import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDesignations,
  addEducation,
  addSocialLinks,
  addWorkExp,
  updateAddSkill,
  updateEducation,
  updateWorkExp,
} from "../actions/userDetailsService";
import { accountSetup } from "../actions/registrationService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import arrowUp1 from "../assets/ArrowUp1.svg";
import arrowUp2 from "../assets/ArrowUp2.svg";
import arrowUp3 from "../assets/ArrowUp3.svg";
import arrowUp4 from "../assets/ArrowUp4.svg";
import arrowUp from "../assets/ArrowUp.svg";
import moment from "moment";
const useProfileView = ({ mode, type, closeEvent }) => {
  const [designation, setDesignation] = useState(null);
  const [tagline, setTagline] = useState(""); // State to store selected designation
  const [intro, setIntro] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [designationError, setDesignationError] = useState("");
  const [taglineError, setTaglineError] = useState("");
  const [showEndDate, setShowEndDate] = useState(true);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const [updatedSkills, setUpdatedSkills] = useState([]);
  const handleDesignationSelect = (selectedOption) => {
    setDesignation(selectedOption); // Update state with selected option
  };
  const handleSkillClick = (skill) => {
    if (selectedSkills.some((person) => person.id === skill.id)) {
      console.log(skill, selectedSkills);
      let obj = { ...skill };
      Object.assign(obj, { status: 0 });
      setSelectedSkills(selectedSkills.filter((s) => s.id !== obj.id));
      setUpdatedSkills([...updatedSkills, obj]);
    } else {
      console.log(skill.id, selectedSkills);
      let obj = { ...skill };
      Object.assign(obj, { status: 1 });
      setSelectedSkills([...selectedSkills, obj]);
      setUpdatedSkills([...updatedSkills, obj]);
    }
  };

  const handleSkillMouseEnter = (skill) => {
    setHoveredSkill(skill);
  };

  const handleSkillMouseLeave = () => {
    setHoveredSkill(null);
  };
  const {
    designations,
    userDetails,
    workExperiancesArray,
    workExpObj,
    allSkillsArray,
    userSkillsArray,
    socialLinksObj,
    educationObj,
  } = useSelector((state) => ({
    designations: state.user.allDesignations,
    userDetails: state.user.userInfo,
    workExperiancesArray: state.user.workExperiances,
    workExpObj: state.user.workExperianceObj,
    allSkillsArray: state.user.allSkills,
    userSkillsArray: state.user.userSkills,
    socialLinksObj: state.user.socialLinks,
    educationObj: state.user.educationObj,
  }));
  const [socialLinksData, setSocialLinksData] = useState({
    facebook: "",
    linkedin: "",
    instagram: "",
    medium: "",
    twitter: "",
    website: "",
    youtube: "",
  });


  const [links, setLinks] = useState([
    {
      name: "Facebook",
      url:
        // socialLinksObj?.facebook != null ||
        socialLinksObj?.facebook != ""
          ? socialLinksObj?.facebook != undefined && socialLinksObj?.facebook
          : "",
      bgColor: "bg-[#D1E5FF]",
      textColor: "color-blue",
      icon: arrowUp,
    },
    {
      name: "Youtube",
      url:
        // socialLinksObj?.youtube != null ||
        socialLinksObj?.youtube != ""
          ? socialLinksObj?.youtube != undefined && socialLinksObj?.youtube
          : "",
      bgColor: "bg-[#FFDDDD]",
      textColor: "color-red",
      icon: arrowUp1,
    },
    {
      name: "Linkedin",
      url:
        // socialLinksObj?.linkedin != null ||
        socialLinksObj?.linkedin != ""
          ? socialLinksObj?.linkedin != undefined && socialLinksObj?.linkedin
            : "",
      bgColor: "bg-[#DCEDFF]",
      textColor: "color-dark-blue",
      icon: arrowUp2,
    },
    {
      name: "Twitter",
      url:
        // socialLinksObj?.twitter != null ||
        socialLinksObj?.twitter != ""
          ? socialLinksObj?.twitter != undefined && socialLinksObj?.twitter
          : "",
      bgColor: "bg-[#E4E4E4]",
      textColor: "color-black",
      icon: arrowUp3,
    },
    {
      name: "Medium",
      url:
        // socialLinksObj?.medium != null ||
        socialLinksObj?.medium != ""
          ? socialLinksObj?.medium != undefined && socialLinksObj?.medium
            : "",
      bgColor: "bg-[#E4E4E4]",
      textColor: "color-black",
      icon: arrowUp3,
    },
    {
      name: "Instagram",
      url:
        // socialLinksObj?.instagram != null ||
        socialLinksObj?.instagram != ""
          ? socialLinksObj?.instagram != undefined && socialLinksObj?.instagram
          : "",
      bgColor: "insta-bg",
      textColor: "text-[#FECC2F]",
      icon: arrowUp4,
    },
    {
      name: "Website",
      url:
        // socialLinksObj?.website != null ||
        socialLinksObj?.website != ""
          ? socialLinksObj?.website != undefined && socialLinksObj?.website
          : "",
      bgColor: "bg-[#E4E4E4]",
      textColor: "color-black",
      icon: arrowUp3,
    },
  ]);
  const [workExpData, setWorkExpData] = useState({
    showEndDate: false,
    employeeType: "",
    designation: null,
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
    isWorking: false,
    companyName: "",
    job_description: "",
  });
  const [eduErrors, seteduErrors] = useState({
    school: "",
    degree: "",
    field: "",
  });
  const [eduData, setEduData] = useState({
    showEndDate: false,
    school: "",
    degree: "",
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
    isCurrentlyStudying: false,
    fieldOfStudy: "",
    isDroppedOut: false,
  });
  const handleWorkingStatusChange = (e) => {
    // setIsWorking();
    console.log(e.target.checked, "e.target.checked");
    setShowEndDate(!e.target.checked);
    if (e.target.checked) {
      setWorkExpData({
        ...workExpData,
        endMonth: null,
        endYear: null,
      });
    }

    setWorkExpData({
      ...workExpData,
      isWorking: e.target.checked,
    });
  };
  const handleRemoveSelection = () => {
    setDesignation(null);
  };
  const handleCurrentlyStudyingChange = (e) => {
    // setIsWorking();
    console.log(e.target.checked, "e.target.checked");
    setShowEndDate(!e.target.checked);
    if (e.target.checked) {
      setEduData({
        ...eduData,
        endMonth: null,
        endYear: null,
      });
    }

    setEduData({
      ...eduData,
      isCurrentlyStudying: e.target.checked,
    });
  };
  useEffect(() => {
    if (userDetails != null) {
      console.log(mode, "modemodemode");
      if (mode && type == "DESIGNATION") {
        let obj = {
          value: userDetails?.designation.name,
          label: userDetails?.designation.name,
        };
        setDesignation(obj);
      }
      if (mode && type == "TAGLINE") {
        setTagline(userDetails?.tag_line);
      }
      if (mode && type == "INTRO") {
        setIntro(userDetails?.intro);
      }
      if (mode && type == "WORKEXP") {
        let obj = {
          value: workExpObj?.designation.name,
          label: workExpObj?.designation.name,
          id: workExpObj?.designation.id,
        };
        const dateString = workExpObj?.start_date_year;
        const endDateString = workExpObj?.end_date_year;
        const [startmonth, startyear] =
          dateString != null ? dateString.split("/") : "";
        const [endmonth, endyear] =
          endDateString != null ? endDateString.split("/") : "";
        // Create a date-time object using Moment.js
        console.log(startmonth, endmonth, typeof startmonth, "endmonth");
        const startDate =
          startmonth != "" && moment(`01-${startmonth}-${startyear}`);
        const endDate = endmonth != "" && moment(`${endmonth}-${endyear}-01`);
        console.log(startDate, endDate, Date("01-02-2026"));
        setDesignation(obj);
        setWorkExpData({
          ...workExpData,
          showEndDate: workExpData.showEndDate,
          employeeType: workExpObj?.employment_type,
          designation: obj,
          startMonth: Date(startDate),
          startYear: Date(startDate),
          endMonth: Date(endDate),
          endYear: Date(endDate),
          isWorking: workExpData?.working_here,
          companyName: workExpObj?.company_name,
          location: workExpObj?.location,
          job_description: workExpObj?.job_description,
        });
      }
      if (mode && type == "SKILLS") {
        setSelectedSkills(userSkillsArray);
      }
      if (mode && type == "EDUCATION") {
        // let obj = {
        //   value: workExpObj?.designation.name,
        //   label: workExpObj?.designation.name,
        //   id: workExpObj?.designation.id,
        // };
        const dateString = educationObj?.start_date_year;
        const endDateString = educationObj?.end_date_year;
        const [startmonth, startyear] =
          dateString != null ? dateString.split("/") : "";
        const [endmonth, endyear] =
          dateString != null ? endDateString.split("/") : "";
        // Create a date-time object using Moment.js
        console.log(startmonth, endmonth, typeof startmonth, "endmonth");
        const startDate =
          startmonth != "" && moment(`01-${startmonth}-${startyear}`);
        const endDate = endmonth != "" && moment(`${endmonth}-${endyear}-01`);
        console.log(startDate, endDate, Date("01-02-2026"));
        // setDesignation(obj);
        setEduData({
          ...eduData,
          showEndDate: educationObj.showEndDate,
          school: educationObj?.institute_name,
          startMonth: Date(startDate),
          startYear: Date(startDate),
          endMonth: Date(endDate),
          endYear: Date(endDate),
          isDroppedOut: educationObj?.dropped,
          fieldOfStudy: educationObj?.location,
          degree: educationObj?.degree,
          id: educationObj?.id,
          // job_description: educationObj?.job_description,
        });
      }
      if (mode && type == "SOCIALLINKS") {
        setSocialLinksData({
          ...socialLinksData,
          facebook: socialLinksData?.facebook,
          linkedin: socialLinksData?.linkedin,
          instagram: socialLinksData?.instagram,
          medium: socialLinksData?.medium,
          twitter: socialLinksData?.twitter,
          website: socialLinksData?.website,
          youtube: socialLinksData?.youtube,
        });
      }
    }
  }, [mode]);
  useEffect(() => {
    console.log(socialLinksData, "datauseeffect");
    setSocialLinksData({
      ...socialLinksData,
      facebook: socialLinksData?.facebook,
      youtube: socialLinksData?.youtube,
      linkedin: socialLinksData?.linkedin,
    });
  }, [
    socialLinksData?.facebook,
    socialLinksData?.linkedin,
    socialLinksData?.youtube,
  ]);

  const saveDesignation = async () => {
    if (designation == null && type == "DESIGNATION") {
      setDesignationError("Designation is required");
      return;
    }
    if (tagline == "" && type == "TAGLINE") {
      setTaglineError("Tagline is required");
      return;
    }
    // if (intro == "" && type == "INTRO") {
    //   setTaglineError("Intro is required");
    //   return;
    // }
    if (designation == null && type == "WORKEXP") {
      setDesignationError("Designation is required");
      return;
    }
    if (workExpData?.companyName == "" && type == "WORKEXP") {
      setDesignationError("Company is required");
      return;
    }
    if (workExpData?.location == "" && type == "WORKEXP") {
      setDesignationError("Location is required");
      return;
    }
    if (eduData?.school == "" && type == "EDUCATION") {
      seteduErrors({
        ...eduErrors,
        school: "School or University is required",
      });
      return;
    }

    if (eduData?.degree == "" && type == "EDUCATION") {
      seteduErrors({
        ...eduErrors,
        degree: "Degree is required",
      });
      return;
    }
    if (eduData?.fieldOfStudy == "" && type == "EDUCATION") {
      seteduErrors({
        ...eduErrors,
        field: "Field of study is required",
      });
      return;
    }
    // const pattern = new RegExp(
    //   "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
    // );
    // if (type == "SOCIALLINKS" && socialLinksData?.facebook != "") {
    //   pattern.test(socialLinksData?.facebook);
    //   toast.error("Please enter valid Url fa");
    //   return;
    // }
    // if (type == "SOCIALLINKS" && socialLinksData?.linkedin != "") {
    //   pattern.test(socialLinksData?.linkedin);
    //   toast.error("Please enter valid Url li");
    //   return;
    // }
    // if (type == "SOCIALLINKS" && socialLinksData?.instagram != "") {
    //   pattern.test(socialLinksData?.instagram);
    //   toast.error("Please enter valid Url in");
    //   return;
    // }
    // if (
    //   type == "SOCIALLINKS" &&
    //   socialLinksData?.youtube != "" &&
    //   pattern.test(socialLinksData?.youtube)
    // ) {
    //   toast.error("Please enter valid Url youddd");
    //   return;
    // }
    // if (type == "SOCIALLINKS" && socialLinksData?.website != "") {
    //   pattern.test(socialLinksData?.website);
    //   toast.error("Please enter valid Url we");
    //   return;
    // }
    // if (type == "SOCIALLINKS" && socialLinksData?.medium != "") {
    //   pattern.test(socialLinksData?.medium);
    //   toast.error("Please enter valid Url me");
    //   return;
    // }
    // if (type == "SOCIALLINKS" && socialLinksData?.twitter != "") {
    //   pattern.test(socialLinksData?.twitter);
    //   toast.error("Please enter valid Url tw");
    //   return;
    // }
    if (type == "DESIGNATION") {
      let obj = {
        name: designation?.value,
      };
      await addDesignations(obj).then(
        (response) => {
          if (response.status === 200) {
            toast.success("Designation added ", {
              toastId: "success1",
            });
            let obj = {
              designation_id: response.data.id,
              username: userDetails?.username,
            };
            accountSetup(obj).then(
              (response) => {
                if (response.status === 200) {
                  toast.success("Setup account", {
                    toastId: "success1",
                  });
                  closeEvent(false);
                  // navigate("/");
                }
              },
              (error) => {
                toast.error(error.response.data.detail, {
                  toastId: "error1",
                });
              }
            );
          }
        },
        (error) => {
          toast.error(error.response.data.detail, {
            toastId: "error1",
          });
        }
      );
    }
    if (type == "TAGLINE") {
      let obj = {
        tag_line: tagline,
      };

      await accountSetup(obj).then(
        (response) => {
          if (response.status === 200) {
            toast.success("Setup account", {
              toastId: "success1",
            });
            closeEvent(false);
            // navigate("/");
          }
        },
        (error) => {
          toast.error(error.response.data.detail, {
            toastId: "error1",
          });
        }
      );
    }
    if (type == "INTRO") {
      let obj = {
        intro: intro,
      };

      await accountSetup(obj).then(
        (response) => {
          if (response.status === 200) {
            toast.success("Setup account", {
              toastId: "success1",
            });
            closeEvent(false);
            // navigate("/");
          }
        },
        (error) => {
          toast.error(error.response.data.detail, {
            toastId: "error1",
          });
        }
      );
    }
    if (type == "WORKEXP") {
      console.log(workExpData?.isWorking);
      let obj = {
        employment_type: workExpData?.employeeType,
        company_name: workExpData?.companyName,
        location: workExpData?.location,
        start_date_year:
          workExpData?.startMonth || workExpData?.startYear != null
            ? moment(workExpData?.startMonth).format("MM") +
              "/" +
              moment(workExpData?.startYear).format("YYYY")
            : null,
        end_date_year: workExpData?.isWorking
          ? null
          : workExpData?.startMonth || workExpData?.startYear != null
          ? moment(workExpData?.endMonth).format("MM") +
            "/" +
            moment(workExpData?.endYear).format("YYYY")
          : null,
        job_description: workExpData?.job_description,
        working_here: workExpData?.isWorking,
        designation_id: designation.id,
        id: mode == true ? workExpObj?.id : 0,
      };

      if (mode) {
        await updateWorkExp(obj).then(
          (response) => {
            if (response.status === 200) {
              toast.success("Exp added", {
                toastId: "success1",
              });
              closeEvent(false);
              // navigate("/");
            }
          },
          (error) => {
            toast.error(error.response.data.detail, {
              toastId: "error1",
            });
          }
        );
      } else {
        await addWorkExp(obj).then(
          (response) => {
            if (response.status === 200) {
              toast.success("Exp added", {
                toastId: "success1",
              });
              closeEvent(false);
              // navigate("/");
            }
          },
          (error) => {
            toast.error(error.response.data.detail, {
              toastId: "error1",
            });
          }
        );
      }
    }
    if (type == "SKILLS") {
      console.log(updatedSkills, "selectedSkills");
      await updateAddSkill(updatedSkills).then(
        (response) => {
          if (response.status === 200) {
            toast.success("skills added/updated", {
              toastId: "success1",
            });
            closeEvent(false);
            // navigate("/");
          }
        },
        (error) => {
          toast.error(error.response.data.detail, {
            toastId: "error1",
          });
        }
      );
    }
    if (type == "EDUCATION") {
      let obj = {
        institute_name: eduData?.school,
        location: eduData?.fieldOfStudy,
        degree: eduData?.degree,
        start_date_year:
          eduData?.startMonth || eduData?.startYear != null
            ? moment(eduData?.startMonth).format("MM") +
              "/" +
              moment(eduData?.startYear).format("YYYY")
            : null,
        end_date_year: eduData?.isWorking
          ? null
          : eduData?.startMonth || eduData?.startYear != null
          ? moment(eduData?.endMonth).format("MM") +
            "/" +
            moment(eduData?.endYear).format("YYYY")
          : null,
        dropped: eduData?.isDroppedOut,
        id: mode == true ? eduData?.id : 0,
      };
      if (mode) {
        await updateEducation(obj).then(
          (response) => {
            if (response.status === 200) {
              toast.success("Edu added", {
                toastId: "success1",
              });
              closeEvent(false);
              // navigate("/");
            }
          },
          (error) => {
            toast.error(error.response.data.detail, {
              toastId: "error1",
            });
          }
        );
      } else {
        await addEducation(obj).then(
          (response) => {
            if (response.status === 200) {
              toast.success("Edu added", {
                toastId: "success1",
              });
              closeEvent(false);
              // navigate("/");
            }
          },
          (error) => {
            toast.error(error.response.data.detail, {
              toastId: "error1",
            });
          }
        );
      }
    }
    if (type == "SOCIALLINKS") {
      console.log(socialLinksData?.linkedin, "api");
      let obj = {
        facebook: socialLinksData?.facebook,
        youtube: socialLinksData?.youtube,
        linkedin: socialLinksData?.linkedin,
        twitter: socialLinksData?.twitter,
        medium: socialLinksData?.medium,
        instagram: socialLinksData?.instagram,
        website: socialLinksData?.website,
        other: "",
      };
      await addSocialLinks(obj).then(
        (response) => {
          if (response.status === 200) {
            toast.success("links added", {
              toastId: "success1",
            });
            closeEvent(false);
            // navigate("/");
          }
        },
        (error) => {
          toast.error(error.response.data.detail, {
            toastId: "error1",
          });
        }
      );
    }
  };
  return {
    designationError,
    handleDesignationSelect,
    handleRemoveSelection,
    saveDesignation,
    designations,
    designation,
    setTagline,
    tagline,
    taglineError,
    intro,
    setIntro,
    setWorkExpData,
    workExpData,
    handleWorkingStatusChange,
    setShowEndDate,
    showEndDate,
    allSkillsArray,
    handleSkillClick,
    handleSkillMouseEnter,
    handleSkillMouseLeave,
    hoveredSkill,
    selectedSkills,
    links,
    setLinks,
    socialLinksObj,
    setSocialLinksData,
    socialLinksData,
    eduData,
    setEduData,
    handleCurrentlyStudyingChange,
    eduErrors,
  };
};
export default useProfileView;
