import React, { useState } from "react";
import arrowUp from "../../assets/ArrowUp.svg";
import arrowUp1 from "../../assets/ArrowUp1.svg";
import arrowUp2 from "../../assets/ArrowUp2.svg";
import arrowUp3 from "../../assets/ArrowUp3.svg";
import arrowUp4 from "../../assets/ArrowUp4.svg";
import verify from "../../assets/verify.svg";
import close from "../../assets/close.png";
import useProfileView from "../../hooks/useProfile";

const AddSocialLinks = ({ edit, closeEvent }) => {
  const {
    saveDesignation,
    taglineError,
    allSkillsArray,
    handleSkillClick,
    handleSkillMouseEnter,
    handleSkillMouseLeave,
    hoveredSkill,
    selectedSkills,
    links,
    setLinks,
    setSocialLinksData,
    socialLinksData,
  } = useProfileView({
    mode: edit,
    type: "SOCIALLINKS",
    closeEvent,
  });
  const handleInputChange = (url, name, index) => {
    console.log(url, name, "url, name");

    const newLinks = [...links];
    newLinks[index].url = url;
    setLinks(newLinks);
    setSocialLinksData({
      ...socialLinksData,
      [name]: url,
    });
  };

  // const handleSubmit = () => {
  //   const validLinks = links.filter((link) => link.url.trim() !== "");
  //   onSave(validLinks);
  // };
  // console.log(socialLinksData);
  return (
    <div className="">
      {/* <div className={`flex flex-col gap-4`}> */}

      <div className="flex flex-col gap-4 mb-10 ">
        {links.map((link, index) => (
          <div
            key={index}
            className="w-full border-[1px] border-black br-20 p-2"
          >
            <div className="w-full flex items-center gap-5">
              <div
                className={`px-2 py-[13px] md:px-4 md:py-5 w-[108px] h-[37px] md:w-[131px] md:h-[39px] flex justify-center items-center gap-3 br-15 ${link.bgColor} ${link.textColor}`}
              >
                <p className="text-base">{link.name}</p>
                <img src={link.icon} alt={link.name} />
              </div>
              <input
                type="url"
                placeholder="Paste your link here"
                className="w-full color-black text-lg font-normal"
                value={link?.url}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    link.name.toLowerCase(),
                    index
                  )
                }
              />
            </div>
          </div>
        ))}

        <div className="ml-[82%] flex  gap-2">
          <div
            onClick={() => {
              saveDesignation();
            }}
            className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
          >
            <button>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSocialLinks;
