import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    // advanceDataInfo: {},
  },
  reducers: {
    // advanceDataInfo: (state, action) => {
    //   state.advanceDataInfo = action.payload;
    // }
  },
});

export const {
  //   advanceDataInfo,
} = authSlice.actions;

export default authSlice.reducer;
