import axiosCommon from "../axios";

export function getUserDetails() {
  return axiosCommon.get(`user/me`);
}
export function userNameCheck(username) {
  return axiosCommon.get(`user/check-username/${username}`);
}
export function getDesignations() {
  return axiosCommon.get(`designations`);
}
export function addDesignations(obj) {
  return axiosCommon.post(`designations`, obj);
}
export function getWorkExp(username) {
  return axiosCommon.get(`work-experience/${username}`);
}
export function addWorkExp(obj) {
  return axiosCommon.post(`work-experience`, obj);
}
export function updateWorkExp(obj) {
  return axiosCommon.put(`work-experience`, obj);
}
export function deleteWorkExp(id) {
  return axiosCommon.delete(`work-experience/${id}`);
}
export function getAllSkills() {
  return axiosCommon.get(`skills`);
}
export function updateAddSkill(obj) {
  return axiosCommon.post(`user/skills`, obj);
}
export function getUserSkills(username) {
  return axiosCommon.get(`user/skills/${username}`);
}
export function getSocialLinks(username) {
  return axiosCommon.get(`social-links/${username}`);
}
export function addSocialLinks(obj) {
  return axiosCommon.post(`social-links`, obj);
}
export function getEducations(username) {
  return axiosCommon.get(`education/${username}`);
}
export function addEducation(obj) {
  return axiosCommon.post(`education`, obj);
}
export function updateEducation(obj) {
  return axiosCommon.put(`education`, obj);
}

export function uploadProfilePicture(formData, token) {
  return axiosCommon.post(`user/profile/picture`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    
  });
}

export function getProfilePicture(username) {
  return axiosCommon.get(`user/profile/picture/${username}`,{
    responseType: 'arraybuffer'

  });
}


// Dashboard
export function getAnalyticsData() {
  return axiosCommon.get(`dashboard`);
}

export function getUserStatus(username) {
  return axiosCommon.get(`user/status/${username}`);
}

export function updateUserStatus(obj) {
  return axiosCommon.post(`user/status`,obj);
}

export function getNotificationSettings() {
  return axiosCommon.get(`user/settings`);
} 

export function addNotificationSettings(obj) {
  return axiosCommon.post(`user/settings`, obj);
} 

export function getCallHistory() {
  return axiosCommon.get(`user/call-history`);
} 

export function deleteEducation(id) {
  return axiosCommon.delete(`education/${id}`);
}

export default {
  getUserDetails,
  userNameCheck,
  getDesignations,
  addDesignations,
  getWorkExp,
  addWorkExp,
  updateWorkExp,
  deleteWorkExp,
  getAllSkills,
  updateAddSkill,
  getUserSkills,
  getSocialLinks,
  addSocialLinks,
  getEducations,
  addEducation,
  updateEducation,
  deleteEducation,
  uploadProfilePicture,
  getProfilePicture,
  getAnalyticsData,
  getUserStatus,
  updateUserStatus,
  getCallHistory,
  addNotificationSettings,
};
