import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import countryList from "country-list";
import "flag-icons/css/flag-icons.min.css"; // Import flag icons

const CountryCitySelector = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState("");

  const countries = countryList.getData(); // Get list of countries

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
    fetchCities(selectedOption.label.props.children[1]); // Fetch cities using country name
    setCity(""); // Reset city when the country changes
  };

  const handleCityChange = (selectedCity) => {
    setCity(selectedCity.value);
  };

  const fetchCities = async (countryName) => {
    try {
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/cities",
        {
          country: countryName,
        }
      );
      const cities = response.data.data;
      const cityOptions = cities.map((city) => ({
        value: city,
        label: city,
      }));
      setCityOptions(cityOptions);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const countryOptions = countries.map((country) => ({
    value: country.code,
    label: (
      <div className="flex items-center mr-4">
        <span className={`fi fi-${country.code.toLowerCase()} mr-2`}></span>
        {country.name}
      </div>
    ),
  }));

  return (
    <div className="h-full">
      <div className="flex flex-col justify-between h-full">
      <div className={`flex flex-col gap-6 `}>
        <p className="font-18 color-black font-medium letterSpace">
          Whats your Country ? *
        </p>
        <div className="w-full flex gap-4">
          <div className="mb-4 w-[50%]">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="country"
            >
              Country
            </label>
            <Select
              options={countryOptions}
              onChange={handleCountryChange}
              className=" py-2 border rounded-lg shadow-sm  br-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="w-[50%]">
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="city"
            >
              City
            </label>
            <Select
              options={cityOptions}
              onChange={handleCityChange}
              className="w-full py-2 border rounded-lg shadow-sm focus:outline-none br-20 focus:ring-2 focus:ring-blue-500"
              value={cityOptions.find((option) => option.value === city)}
            />
          </div>
        </div>
        </div>

        {/* Save button */}
        <div className=" flex ml-[82%]">
          <div className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20">
            <button>Save</button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default CountryCitySelector;
