import React, { useState, useRef, useEffect } from "react";
import ProfilePicImage from "../../assets/images/SetupAccountImage.png";
import verify from "../../assets/verify.svg";
import { Link, useNavigate } from "react-router-dom";
import { accountSetup } from "../../actions/registrationService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userNameCheck, uploadProfilePicture,
  getProfilePicture, } from "../../actions/userDetailsService";

const SetupAccount = () => {
  const defaultProfilePicUrl = "https://api.rightmentor.io/api/v1/user/profile/picture/user-profile.png";
  const dispatch = useDispatch();
  const {
    userDetails,
   
  } = useSelector((state) => ({
    userDetails: state.user.userInfo,}))

  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(ProfilePicImage); // Initial profile picture
  const fileInputRef = useRef(null);
  const [userName, setUserName] = useState("");
  
  const token = localStorage.getItem("authToken");

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await uploadProfilePicture(formData, token);
        if (response.status === 200) {
          // Assuming response contains the updated userDetails with the new picture URL
          console.log("post response",response)
          const updatedUserDetails = response.data;
          console.log("updated details",updatedUserDetails);
          setProfilePic(updatedUserDetails.picture);
        

          const localImageUrl = URL.createObjectURL(selectedFile);
          setProfilePic(localImageUrl);

          // setFileInputKey(Date.now()); // Reset file input field
          toast.success("Profile picture uploaded successfully!", {
            toastId: "success1",
          });
        }
      } catch (error) {
        toast.error("Error uploading profile picture");
      }
    }
  };


  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        console.log("userDetails=", userDetails);
        const picturePath = userDetails.picture.split('/').pop(); // Extract the picture file name
        const response = await getProfilePicture(picturePath);
        console.log("responsee", response);
        if (response.status === 200) {
          const base64 = btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          setProfilePic(`data:${response.headers['content-type']};base64,${base64}`);
        } else {
          setProfilePic(defaultProfilePicUrl); // Set default picture if response is not 200
        }
      } catch (error) {
        toast.error("Error fetching profile picture");
        setProfilePic(defaultProfilePicUrl); // Set default picture on error
      }
    };

    if (userDetails?.picture) {
      fetchProfilePicture();
    }else{
      setProfilePic(defaultProfilePicUrl); // Set default picture 
    }
  }, [userDetails?.picture]);



  const [formData, setFormData] = useState({
    userType: "mentor",
    occupation: "Professional",
    userName: "",
  });



  const checkUserName = async () => {
    if (formData.userName == "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["userName"]: " Username is required",
      }));
      return;
    }
    await userNameCheck(formData.userName).then(
      (response) => {
        if (response.status === 204) {
          toast.success("Username is available", {
            toastId: "success1",
          });
          setFormData({ ...formData, userName: formData.userName });
          setUserName(formData.userName);
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  };

  const [errors, setErrors] = useState({});
  const submit = async () => {
    if (formData.userType == "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["userType"]: "User Type is required",
      }));
      return;
    }
    if (formData.occupation == "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["occupation"]: "Occupation is required",
      }));
      return;
    }
    if (formData.userName == "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["userName"]: " Username is required",
      }));
      return;
    }

    let obj = {
      user_type: formData.userType,
      occupation: formData.occupation,
      username: userName,
      intro: "",
      designation_id: 0,
      tag_line: "",
    };
    await accountSetup(obj).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Setup account", {
            toastId: "success1",
          });
          navigate("/dashboard");
          localStorage.setItem("setupStatus", "true");
        }
        console.log("responseeeeeeeeeeeeeeeeeeeeeeeee", response);
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  };
  return (
    <div className="Relative lg:mt-14 lg:ml-[40%]  p-4 pb-20 h-auto gap-10 ">
      <div className="flex flex-col gap-24">
        <div className=" max-w-[610px] w-full setup-account relative flex flex-col gap-5 justify-start  bg-white border border-gray br-35 px-4 md:px-[30px] py-10">
          <div>
            <h2 className="color-black font-medium font-22">Setup account</h2>

            <p className="color-gray">
              In a few moments you will be ready to share your expertise & time
            </p>
          </div>

          <div className="flex flex-col md:flex-row  gap-10 ">
            <div className="w-auto h-auto">
              <div className="flex justify-center items-center w-[137px] h-[147px] primary-bg br-30">
                <img
                  className=" w-[96px] h-[96px] rounded-full "
                  src={profilePic}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <p
                onClick={() =>
                  document.querySelector('input[type="file"]').click()
                }
                className="color-blue cursor-pointer text-base font-medium pt-5"
              >
                Change Profile Pic
              </p>
            </div>

            <div className="w-full">
              <div className=" w-full flex flex-col gap-4">
                <p className="color-black">Who are you ? *</p>

                <div
                  className={`flex gap-4 justify-start cursor-pointer items-start border br-20 p-5
                ${
                  formData.userType === "mentor"
                    ? "border-green-400 primary-bg"
                    : "border-black"
                } `}
                  onClick={() =>
                    setFormData({ ...formData, userType: "mentor" })
                  }
                >
                  <input
                    className="custom-radio w-[40px] h-[25px]"
                    type="radio"
                    id="iAmMentor"
                    name="mentorType"
                    value="mentor"
                    checked={formData.userType === "mentor"}
                    onChange={(e) => {
                      setFormData({ ...formData, userType: e.target.value });
                    }}
                  />
                  <div htmlFor="iAmMentor" className="flex flex-col gap-2">
                    <label
                      className={`${
                        formData.userType === "mentor"
                          ? "text-gradient font-bold w-full "
                          : "color-black font-bold w-full"
                      }`}
                      htmlFor="iAmMentor"
                    >
                      i’m a Mentor
                    </label>
                    <p className="font-medium text-sm color-gray">
                      I'm also interested in earning a secondary income through
                      mentoring.
                    </p>
                  </div>
                </div>
                {errors.userType && (
                  <p className="text-[#F00000] text-sm">{errors.userType}</p>
                )}
                <div
                  className={`flex gap-4 justify-center items-start border p-5 cursor-pointer br-20 ${
                    formData.userType === "Mentee"
                      ? "border-green-400 primary-bg"
                      : "border-black"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, userType: "Mentee" })
                  }
                >
                  <input
                    className="custom-radio w-[45px] h-[25px]"
                    type="radio"
                    id="iAmLookingForAMentor"
                    name="mentorType"
                    value="Mentee"
                    checked={formData.userType === "Mentee"}
                    onChange={(e) => {
                      setFormData({ ...formData, userType: e.target.value });
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <label
                      className={` ${
                        formData.userType === "Mentee"
                          ? "text-gradient font-bold"
                          : "color-black font-bold"
                      }`}
                      htmlFor="iAmLookingForAMentor"
                    >
                      I’m a Mentee
                    </label>
                    <p className="font-medium text-sm color-gray">
                      I'm interested in finding a mentor who can help me develop
                      my skills and knowledge.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4 mt-6">
                <p className="color-black">What you do ? *</p>
                <div className="flex flex-col justify-start gap-4">
                  <div
                    className={`flex gap-3 justify-start items-center border br-20 p-4 cursor-pointer ${
                      formData.occupation === "Professional"
                        ? "border-green-400 primary-bg"
                        : "border-black"
                    }`}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        occupation: "Professional",
                      })
                    }
                  >
                    <input
                      className="custom-radio w-[25px] h-[23px] "
                      type="radio"
                      id="Professional"
                      name="profession"
                      value="Professional"
                      checked={formData.occupation === "Professional"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          occupation: e.target.value,
                        });
                      }}
                    />
                    <label
                      className={`cursor-pointer  ${
                        formData.occupation === "Professional"
                          ? "text-gradient w-full font-bold"
                          : "color-black w-full"
                      }`}
                      htmlFor="Professional"
                    >
                      I’m working Professional
                    </label>
                  </div>

                  <div
                    className={`flex gap-3 justify-start items-center border br-20 p-4 cursor-pointer ${
                      formData.occupation === "Student"
                        ? "border-green-400 primary-bg"
                        : "border-black"
                    }`}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        occupation: "Student",
                      })
                    }
                  >
                    {" "}
                    <input
                      className="custom-radio w-[25px] h-[23px] "
                      type="radio"
                      id="Student"
                      name="profession"
                      value="Student"
                      checked={formData.occupation === "Student"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          occupation: e.target.value,
                        });
                      }}
                    />
                    <label
                      className={`cursor-pointer ${
                        formData.occupation === "Student"
                          ? "text-gradient w-full font-bold"
                          : "w-full color-black"
                      }`}
                      htmlFor="Student"
                    >
                      I’m a student
                    </label>
                  </div>
                </div>
              </div>
              {errors.occupation && (
                <p className="text-[#F00000] text-sm">{errors.occupation}</p>
              )}
              <div className="flex flex-col gap-3 mt-6">
                <p className=" color-black">Set user name ? *</p>
                <div className="relative">
                  <input
                    type="text"
                    id="username"
                    className="w-full color-black md:p-4 p-3 font-medium text-base border border-black box-radius"
                    placeholder=""
                    onChange={(e) => {
                      setFormData({ ...formData, userName: e.target.value });
                    }}
                  />
                  {userName == "" ? (
                    <span
                      className="absolute right-4 top-4 cursor-pointer color-blue test-sm"
                      onClick={() => {
                        checkUserName();
                      }}
                    >
                      Check
                    </span>
                  ) : (
                    <span className="cursor-pointer color-green test-sm">
                      Username Available
                    </span>
                  )}
                </div>

                {errors.userName && (
                  <p className="text-[#F00000] text-sm">{errors.userName}</p>
                )}
                <p className=" color-black mb-10">
                  www.rightmentor.com/
                  <span className="color-blue">{formData.userName}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="absolute cursor-pointer bottom-[-30px] right-10 w-[113px] h-[60px] mt-3 text-white bg-custom-gradient p-[10px] flex justify-center items-center text-lg font-semibold border-[1px] br-20 ">
            <div
              onClick={() => {
                submit();
              }}
              className="text-white no-underline"
            >
              Next
            </div>
          </div>

          {/* <div className="hidden md:block z-[5] absolute top-6 left-[-15%]  border-l-2 border-dotted border-l-[#9C9C9C] h-[120%] "></div> */}
        </div>
      </div>
    </div>
  );
};

export default SetupAccount;
