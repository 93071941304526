import React, { useState, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Education from "./components/verify/Education";
import { ToastContainer, toast } from "react-toastify";
import CountryCitySelector from "./components/verify/CountryCitySelector";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import { getUserDetails } from "./actions/userDetailsService";
import { userInfo } from "./reducers/userSlice";
import { useDispatch } from "react-redux";
import SetupAccount from "./components/verify/SetupAccount";
import MyProfileView from "./components/my-profile";
const Register = lazy(() => import("./pages/Register"));
const VerifyEmail = lazy(() => import("./pages/verifyEmail"));
const SignIn = lazy(() => import("./pages/signIn"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const WelcomePage = lazy(() => import("./components/Welcome"));
const AddDesignation = lazy(() => import("./components/verify/AddDesignation"));
const AddSkills = lazy(() => import("./components/verify/AddSkills"));
const AddServices = lazy(() => import("./components/Package"));
const ForgetPassword = lazy(() => import("./components/ForgetPassword"));
const SearchMentor= lazy(() => import("./pages/SearchMentorPage"));
const MentorProfile= lazy(() => import("./components/MentorProfile"));
const PasswordResetMessage = lazy(() =>
  import("./components/PasswordResetMessage")
);

// const ResetYourPassword = lazy(() => import("./components/ResetYourPassword"));
const SetOnlineHours = lazy(() => import("./components/SetOnlineHours"));
const App = () => {
  const [headerText, setHeaderText] = useState("Dashboard");
  const auth = localStorage.getItem("isauth") || "false";
  const token = localStorage.getItem("authToken") || "";
  const [pageURL, setPageURL] = useState(0);
  const dispatch = useDispatch();
  
  useEffect(() => {
    var url = window.location.href.split("?");
    if (url[1]) {
      var newUrl = url[1].replace("access_token=", "");
      console.log(newUrl, "hiii");
      setPageURL(newUrl);
      localStorage.setItem("authToken", newUrl);
    }
    getUserDetails().then(
      (response) => {
        if (response.status === 200) {
          // toast.success("Successful", {
          //   toastId: "success1",
          // });
          dispatch(userInfo(response.data));
          // if (response.data.profile_completion == 0) {
          // }
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  });

  if (token == "" || auth == "false") {
    console.log(auth, !auth);
    return (
      <Router>
        <ToastContainer />
        <div className="min-h-screen  overflow-x-hidden bg-[#f5f5f5] ">
          <Header text={headerText} />
          <div className="w-full h-[100vh] pt-[90px] ">
            <Suspense fallback={<>Loading...</>}>
              <Routes>
                <Route path="/signup" element={<Register />} />
                <Route path="/signin"  element={<SignIn />} />
                <Route path="/verify" element={<VerifyEmail />} />
                <Route path="/" element={<SignIn setHeaderText={""} />} />

                {/* <Route path="/PasswordResetFlow" element={<PasswordResetFlow/>}/> */}
                <Route path="/ForgetPassword" element={<ForgetPassword />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </Router>
    );
  }
  if (token != "" || auth == "true") {
    return (
      <Router>
        <ToastContainer />
        <div className="min-h-screen  overflow-x-hidden bg-[#f5f5f5] ">
          <Header text={headerText} />
          <div className="w-full h-[100vh] pt-[90px] ">
            <Suspense fallback={<>Loading...</>}>
              <Routes>
                {/* <Route path="/" element={<WelcomePage/>} /> */}
                <Route path="/verify" element={<VerifyEmail />} />
                <Route path="/signin"  element={<SignIn />} />
                <Route path="/ForgetPassword" element={<ForgetPassword />} />
                <Route
                  path="/dashboard"
                  element={<Dashboard setHeaderText={setHeaderText} />}
                />
                <Route path="/my-profile" element={<MyProfileView />} />
                <Route path="/setup" element={<SetupAccount />} />
                <Route path="/searchMentor" element={<SearchMentor />} />
                <Route path="/mentorProfile/:profileId" element={<MentorProfile />} />
                {/* <Route path="/" element={<SetOnlineHours />} />  */}
                {/* <Route path="/" element={<VerifyEmail />} /> */}

                {/* <Route path="/ResetYoourPassword" element={<ResetYourPassword/>}/> */}
                {/* <Route path="/PasswordResetMessage" element={<PasswordResetMessage />} /> */}

                {/* <Route path="/VerifyEmail" element={<VerifyEmail />} />  */}
                {/* <Route path="/" element={<Register />} />  */}
                {/* <Route path="/" element={<AddSkills />} /> */}
                <Route path="/addServices" element={<AddServices />} />
                <Route path="/addLocation" element={<CountryCitySelector/>}/>
              </Routes>
            </Suspense>
          </div>
        </div>
      </Router>
    );
  }
};

export default App;
