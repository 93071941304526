import React, { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoMdMenu } from "react-icons/io";
import logo from "../assets/images/logo.png";
import ring from "../assets/ring.svg";
import active from "../assets/active.svg";
import arrowB from "../assets/arrowD.svg";
import profilePic from "../assets/profilePic.svg";
import righttick from "../assets/righttick.svg";
import inActive from "../assets/Inactive.svg";
import logout from "../assets/logout.svg";
import logout_button from "../assets/logout_button.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserStatus , updateUserStatus} from "../actions/userDetailsService";

const Header = ({ text }) => {
  const [isToggled, setIsToggled] = useState(true);
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [selectedOption, setSelectedOption] = useState("Available"); // State to manage selected option
  const [showLogout, setShowLogout] = useState(false); // State to manage logout visibility
  const isLocalAuth = localStorage.getItem("isauth") || "false";
  const location = useLocation();
  const currentURL = location.pathname;
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for availability dropdown
  const logoutRef = useRef(null); // Ref for logout dropdown
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userInfo,
  }));
  // Function to toggle logout visibility
  const toggleLogout = () => {
    setShowLogout(!showLogout);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      if (userDetails && userDetails.username) {
        try {
          const response = await getUserStatus(userDetails.username);
          console.log("Fetched status from API:", response.data); // Log the fetched status
  
          // Correctly set the selectedOption based on the fetched status
          if (response.data.status === true) {
            setSelectedOption("Available");
          } else {
            setSelectedOption("Not Available");
          }
        } catch (error) {
          console.error("Error fetching user status:", error);
          setSelectedOption("Not Available"); // Fallback to "Not Available" if there's an error
        }
      }
    };
  
    fetchStatus();
  }, [userDetails]);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const logout = () => {
    localStorage.setItem("isauth", false);
    navigate("/signin");
    localStorage.setItem("authToken", "");
    window.location.reload();
  };
 

    // Function to handle option selection
    const handleOptionSelect = async (option) => {
      // console.log("Option selected:", option); // Log the selected option
    
      setSelectedOption(option);
      // console.log("Selected option after state update:", option); // Log the option after state update
    
      setIsOpen(false); // Close dropdown after selection
    
      // Prepare the payload with the correct status field expected by the server
      const payload = {
        status: option === "Available", // true for "Available", false for "Not Available"
      };
    
      try {
        // console.log("Preparing to call updateUserStatus with payload:", payload); // Log before API call
        const response = await updateUserStatus(payload); // Send updated status to the server
        console.log("API status  Response:", response.data); // Log the API response
      } catch (error) {
        console.error("Error updating user status:", error.response?.data || error.message);
      }
    };
    


  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    console.log("headers");
    // Function to handle click outside the dropdowns
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    // Add event listener to detect clicks outside the dropdowns
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let headerContent;
  let headerText;

  if (currentURL === "/dashboard") {
    headerText = (
      <p className="font-22 font-medium text-[#404040] hidden md:block">
        {text}
      </p>
    );
  }

  if (currentURL === "/verify" ) {
    headerContent = (
      <ul className={`hidden lg:flex gap-10 `}>
        <li className="text-lg font-bold color-gray">Mentors List</li>
        <li className="text-lg font-bold color-gray">Mentees List</li>
        <li className="text-lg font-bold color-gray">Students List</li>
        <li className="text-lg font-bold color-gray">About Us</li>
      </ul>
    );
  }else if (currentURL === "/searchMentor"){
    headerContent = (
      <div className="w-[81%] flex items-center justify-between ">
      <ul className={`hidden lg:flex gap-10 `}>
        <li className="text-lg font-bold color-gray">Mentors List</li>
        <li className="text-lg font-bold color-gray">Mentees List</li>
        <li className="text-lg font-bold color-gray">Students List</li>
        <li className="text-lg font-bold color-gray">About Us</li>
      </ul>

      <div className=" flex gap-5">
        <div className="border border-[#69BC56] rounded-[16px] py-2 px-5">
        <Link to="/signin"><p className="text-lg text-[#69BC56]" >Login</p></Link>  
        </div>
        <div className=" bg-gradient rounded-[16px] py-2 px-5">
         <Link to="/signup"> <p className="text-lg text-white" >Become a Mentor</p></Link>
        </div>
      </div>
      </div>
    );

  }else if (currentURL === "/dashboard") {
    headerContent = (
      <div className={`flex items-center justify-center md:gap-3 `}>


        <Link to="/searchMentor">search mentor</Link>
        <div className="w-[70px] h-[60px] border-[1px] border-none md:border-gray br-20 flex justify-center items-center ">


          <img className="" src={ring} alt="" />
        </div>

        <div
          ref={dropdownRef}
          onClick={toggleDropdown}
          className="headerText hidden relative md:flex w-[200px] gap-5 justify-center items-center border-[1px] border-gray bg-white br-20 px-[15px] py-[19px]"
        >
          <img
            src={selectedOption === "Available" ? active : inActive}
            alt={selectedOption}
          />
          <p className="text-base color-black">{selectedOption}</p>
          <img src={arrowB} alt="" />

          {isOpen && (
            <div className="transition-all duration-700 absolute flex flex-col gap-2 bottom-[-135px] left-0 mt-2 w-[200px] bg-white border border-gray shadow-lg br-20 px-[15px] py-[19px] rounded-md z-10">
              <div
                className={`flex gap-2 justify-between items-center p-2 cursor-pointer br-20 ${
                  selectedOption === "Available" ? "bg-gray-200" : ""
                }`}
                onClick={() => handleOptionSelect("Available")}
              >
                <div className="flex gap-3">
                  <img src={active} alt="Active" />
                  <p>Available</p>
                </div>
                {selectedOption === "Available" && (
                  <img src={righttick} alt="Selected" />
                )}
              </div>
              <div
                className={`flex gap-2 items-center justify-between p-2 cursor-pointer br-20 ${
                  selectedOption === "Not Available" ? "bg-gray-200" : ""
                }`}
                onClick={() => handleOptionSelect("Not Available")}
              >
                <div className="flex gap-3">
                  <img src={inActive} alt="Inactive" />
                  <p>Not Available</p>
                </div>
                {selectedOption === "Not Available" && (
                  <img src={righttick} alt="Selected" />
                )}
              </div>
            </div>
          )}
        </div>

        <div
          ref={logoutRef}
          className="flex relative gap-10  bg-[#F0FFED] md:primary-bg br-25 md:px-3 py-2"
          onClick={toggleLogout}
        >
          <div className="flex gap-3 justify-center  items-center">
            <div className="w-10 h-10">
              <div className="w-full h-full border-gradient rounded-full flex justify-center items-center">
                <img src={profilePic} alt="Profile" />
              </div>
            </div>
            <div className="hidden md:block">
              <p className="color-black text-base">{userDetails?.given_name}</p>
              <p className="color-gray text-base">
                {userDetails?.designation?.name
                  ? userDetails?.designation?.name
                  : "-"}
              </p>
            </div>
          </div>
          <div className="hidden md:flex justify-center items-center ">
            <div className="w-4 h-2">
              <img src={arrowB} alt="Arrow" />
            </div>
          </div>

          {showLogout && (
            <div
              className="absolute flex items-center bg-[#FFDEDF] border border-red w-full h-16 left-0 bottom-[-72px] shadow-lg mt-2 md:primary-bg br-25 md:px-5 py-2"
              onClick={() => {
                logout();
              }}
            >
              <div className="flex justify-between gap-5 items-center">
                <img src={logout_button} alt="" />
                <p className="text-lg text-[#D13438] cursor-pointer">Logout</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    headerContent = <></>;
  }

  return (
    <div className="fixed w-full h-[90px] z-[100] bg-[#ffffff]">
      <div
        className={`max-w-7xl w-full m-auto h-full  flex items-center  justify-around lg:justify-between lg:p-5 `}
      >
        <div
          className={`w-full flex items-center   p-4 ${
            currentURL === "/verify" || currentURL === "/searchMentor"
              ? "justify-start gap-10 "
              : "  justify-between"
          }`}
        >
          <div className=" flex gap-5 justify-center">
            <img className="w-48 h-9" src={logo} alt="Logo" />
            {headerText}
          </div>
          {headerContent}
        </div>

        <div onClick={handleToggle} className="hidden">
          {isToggled ? (
            <IoMdMenu className="primary-text-gradient" size={40} />
          ) : (
            <RxCross2 className="primary-text-gradient" size={40} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
