import axios from "axios";
const isLocalAuth = localStorage.getItem("isauth");
// const loginData = JSON.parse(localStorage.getItem("loginResponse"));
// const clientId = isLocalAuth == "true" ? loginData.authKeys.clientId : "";
// const clientSecret =
//   isLocalAuth == "true" ? loginData.authKeys.clientSecret : "";
const token = localStorage.getItem("authToken") || "";
const instance = axios.create({
  baseURL: "https://api.rightmentor.io/api/v1",
});
instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Authorization"] =
  token != "" ? "Bearer " + token : "";

export default instance;
