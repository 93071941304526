

import axiosCommon from "../axios";
export function signupService(obj) {
  return axiosCommon.post(`/user/signup`, obj);
}
export function authTokenSignIn(email, password) {
  console.log(axiosCommon, "axiosCommon");
  return axiosCommon.post(
    `auth/token?grant_type=passwordusername=${email}&password=${password}`
  );
}
export function sendOTP(obj) {
  return axiosCommon.post(`auth/send-otp`, obj);
}
export function accountSetup(obj) {
  return axiosCommon.post(`user/setup`, obj);
}
export function verifyOTP(obj) {
  return axiosCommon.post(`auth/verify-otp`, obj);
}


export function updatePassword(obj, token) {
  return axiosCommon.post(`auth/change-password`, obj);
}


export function addService(obj, token) {
  return axiosCommon.post(`packages/chat`, obj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteService(service_id, token) {
  return axiosCommon.delete(`packages/${service_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addCallService(obj, token) {
  return axiosCommon.post(`packages/call`, obj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export function getServices(username, token) {
  return axiosCommon.get(`packages/${username}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


// New function to fetch designations
export function getDesignations() {
  return axiosCommon.get('designations');
}

export function searchMentor(obj, token) {
  return axiosCommon.post(`search`, obj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export default {
  signupService,
  authTokenSignIn,
  sendOTP,
  accountSetup,
  verifyOTP,
  updatePassword,
  addCallService,
  getDesignations
};
