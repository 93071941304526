import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useProfileView from "../../hooks/useProfile";

const WhatsYourExperience = ({ edit, closeEvent }) => {
  // const [focusedType, setFocusedType] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [startMonth, setStartMonth] = useState(null);
  // const [startYear, setStartYear] = useState(null);
  // const [endMonth, setEndMonth] = useState(null);
  // const [endYear, setEndYear] = useState(null);
  // const handleSave = () => {
  //   const workExperience = {
  //     designation,
  //     startMonth,
  //     startYear,
  //     endMonth: isWorking ? null : endMonth,
  //     endYear: isWorking ? null : endYear,
  //     isWorking,
  //     workType: focusedType,
  //     companyName: document.getElementById("companyName").value,
  //     location: document.getElementById("location").value,
  //     description: document.getElementById("description").value,
  //   };
  //   onSave(workExperience);
  // };

  // const handleFocus = (type) => {
  //   setFocusedType(type);
  // };

  // const handleBlur = () => {
  //   setFocusedType("");
  // };

  // const handleDesignationSelect = (selectedOption) => {
  //   setDesignation(selectedOption.value);
  // };

  // const handleMonthChange = (date) => {
  //   setStartMonth(date);
  // };

  // const handleYearChange = (date) => {
  //   setStartYear(date);
  // };

  // const handleEndMonthChange = (date) => {
  //   setEndMonth(date);
  // };

  // const handleEndYearChange = (date) => {
  //   setEndYear(date);
  // };

  // const designations = [
  //   "Technology Software Engineer Chief Financial Officer (CFO)",
  //   "Data Scientist",
  //   "Systems Analyst",
  //   "Network Administrator",
  //   "Product Manager",
  //   "UX/UI Designer",
  //   "DevOps Engineer",
  //   "Chief Technology Officer (CTO)",
  //   "Information Security Analyst",
  //   "Finance",
  //   "Financial Analyst",
  //   "Investment Banker",
  //   "Accountant",
  // ];

  // const designationOptions = designations.map((designation) => ({
  //   value: designation,
  //   label: designation,
  // }));

  const employmentTypes = [
    "Full-time",
    "Part-time",
    "Self-employed",
    "Internship",
    "Trainee",
    "Freelance",
  ];

  const {
    saveDesignation,
    setWorkExpData,
    workExpData,
    designations,
    designation,
    handleDesignationSelect,
    handleRemoveSelection,
    designationError,
    handleWorkingStatusChange,
    setShowEndDate,
    showEndDate,
  } = useProfileView({
    mode: edit,
    type: "WORKEXP",
    closeEvent,
  });
  console.log(workExpData, "workExpDataworkExpDataworkExpData");
  return (
    <>
      <div className="h-full flex flex-col justify-between">
        {/* <div className={`flex flex-col gap-4`}> */}
<div>
        <div className={` flex w-full gap-6`}>
          <div className={`flex flex-col gap-3`} style={{ width: "50%" }}>
            <p>Whats your Designation ? *</p>
            <div className="w-full relative">
              <Select
                value={designation}
                className="color-black p-[11px] font-medium text-base border-black box-radius"
                options={
                  designations.length > 0 &&
                  designations.map((designation) => ({
                    value: designation.name,
                    label: designation.name,
                    id: designation.id,
                  }))
                }
                placeholder="Click here and select"
                onChange={handleDesignationSelect}
              />
              {designation && (
                <button
                  className="absolute top-[15px] right-[60px] ml-2 p-1 text-gray-500 rounded"
                  onClick={handleRemoveSelection}
                >
                  ✕
                </button>
              )}
            </div>
            {designationError && (
              <p className="color-red text-sm">{designationError}</p>
            )}
          </div>

          <div className={`flex flex-col `} style={{ width: "50%" }}>
            <p className="color-black">Employment type ?</p>
            <div className="flex flex-wrap gap-3 justify-start items-start py-3">
              {employmentTypes.map((type) => (
                <div
                  key={type}
                  className={`py-2 px-4 br-15 cursor-pointer  ${
                    workExpData?.employeeType === type
                      ? "bg-custom-gradient text-white"
                      : "bg-[#F0FFED] hover:bg-custom-gradient hover:text-white"
                  }`}
                  tabIndex="0"
                  onClick={() => {
                    setWorkExpData({
                      ...workExpData,
                      employeeType: type,
                    });
                  }}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={` flex gap-6`}>
          <div className={`flex flex-col gap-4 w-50`}>
            <div className="flex flex-col gap-3 justify-start items-start ">
              <p className=" color-black">Company name ? *</p>
              <input
                type="text"
                className="w-full color-black p-[18px] font-medium text-base border border-black box-radius"
                placeholder="Type Company name here"
                value={workExpData?.companyName}
                onChange={(e) => {
                  setWorkExpData({
                    ...workExpData,
                    companyName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="flex flex-col gap-3 justify-start items-start ">
              <p className=" color-black">Location *</p>
              <input
                type="text"
                value={workExpData?.location}
                className="w-full color-black md:p-[18px] p-[18px] font-medium text-base border border-black box-radius"
                placeholder="Type Location here"
                onChange={(e) => {
                  setWorkExpData({
                    ...workExpData,
                    location: e.target.value,
                  });
                }}
              />
            </div>
            <div className=" flex justify-start items-start gap-3  border-0 border-t-[#D9D9D9] border-b-[#D9D9D9]">
              <input
                type="checkbox"
                id="isWorking"
                className="mb-2 font-medium text-base  custom-checkbox br-10 border border-black "
                onChange={handleWorkingStatusChange}
              />
              <label
                htmlFor="isWorking"
                className="block font-medium text-base color-black"
              >
                I am currently working in this role
              </label>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className={`flex flex-col gap-3`}>
              <p className="color-black">Start date</p>
              <div className={`flex gap-2 w-full`}>
                <div className="w-full color-black p-[18px] font-medium text-base border border-black box-radius">
                  <DatePicker
                    selected={workExpData?.startMonth}
                    onChange={(date) => {
                      setWorkExpData({
                        ...workExpData,
                        startMonth: date,
                      });
                    }}
                    dateFormat="MMM"
                    showMonthYearPicker
                    showFullMonthYearPicker={false}
                    showMonthDropdown
                    placeholderText="Select Month"
                    className="block w-full shadow-sm text-blue-500 focus:outline-none cursor-pointer focus:border-indigo-500"
                  />
                </div>
                <div
                  className={`w-full color-black p-[18px] font-medium text-base border border-black box-radius 
                    
                  }`}
                >
                  <DatePicker
                    selected={workExpData?.startYear}
                    onChange={(date) => {
                      setWorkExpData({
                        ...workExpData,
                        startYear: date,
                      });
                    }}
                    dateFormat="yyyy"
                    showYearPicker
                    placeholderText="Select Year"
                    className="block w-full shadow-sm focus:outline-none cursor-pointer text-blue-500 focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>
            {showEndDate && (
              <div className={`flex flex-col gap-3`}>
                <p className="color-black">End date</p>
                <div className="flex gap-2">
                  <div className="w-full color-black p-[18px] font-medium text-base border border-black box-radius ">
                    <DatePicker
                      selected={workExpData?.endMonth}
                      onChange={(date) => {
                        setWorkExpData({
                          ...workExpData,
                          endMonth: date,
                        });
                      }}
                      dateFormat="MMM"
                      showMonthYearPicker
                      showFullMonthYearPicker={false}
                      showMonthDropdown
                      placeholderText="Select Month"
                      className="block w-full shadow-sm focus:outline-none text-blue-500 cursor-pointer focus:border-indigo-500"
                    />
                  </div>
                  <div
                    className={`w-full color-black p-[18px] font-medium text-base border border-black box-radius
                    }`}
                  >
                    <DatePicker
                      selected={workExpData?.endYear}
                      onChange={(date) => {
                        setWorkExpData({
                          ...workExpData,
                          endYear: date,
                        });
                      }}
                      dateFormat="yyyy"
                      showYearPicker
                      placeholderText="Select Year"
                      className="block w-full shadow-sm focus:outline-none text-blue-500 cursor-pointer focus:border-indigo-500"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`flex flex-col gap-3 mt-2`}>
          <label
            htmlFor="description"
            className={`block font-medium text-base color-black `}
          >
            Job Description
          </label>
          <textarea
            id="description"
            className={`mb-2 font-medium text-base w-full  br-20 border border-black p-2`}
            placeholder="Describe your current role..."
            onChange={(e) => {
              setWorkExpData({
                ...workExpData,
                job_description: e.target.value,
              });
            }}
            value={workExpData?.job_description}
          ></textarea>
        </div>
        </div>
        {/* Save button */}
        <div className="ml-[82%] flex gap-2 ">
          <div
            onClick={() => {
              saveDesignation();
            }}
            className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
          >
            <button>Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsYourExperience;
