import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import verify from "../../assets/verify.svg";
import close from "../../assets/close.png";
import useProfileView from "../../hooks/useProfile";

const WhatsYourEducation = ({ edit, closeEvent }) => {
  // const [school, setSchool] = useState("");
  // const [degree, setDegree] = useState("");
  // const [fieldOfStudy, setFieldOfStudy] = useState("");
  // const [startMonth, setStartMonth] = useState(null);
  // const [startYear, setStartYear] = useState(null);
  // const [endMonth, setEndMonth] = useState(null);
  // const [endYear, setEndYear] = useState(null);
  // const [isCurrentlyStudying, setIsCurrentlyStudying] = useState(false);

  // const handleSave = () => {
  //   const educationDetails = {
  //     school,
  //     degree,
  //     fieldOfStudy,
  //     startMonth,
  //     startYear,
  //     endMonth: isCurrentlyStudying ? null : endMonth,
  //     endYear: isCurrentlyStudying ? null : endYear,
  //     isCurrentlyStudying,
  //   };
  //   // onSave(educationDetails);
  // };

  // const handleCurrentlyStudyingChange = (e) => {
  //   setIsCurrentlyStudying(e.target.checked);
  //   if (e.target.checked) {
  //     setEndMonth(null);
  //     setEndYear(null);
  //   }
  // };

  // const handleMonthChange = (date) => {
  //   setStartMonth(date);
  // };

  // const handleYearChange = (date) => {
  //   setStartYear(date);
  // };

  // const handleEndMonthChange = (date) => {
  //   setEndMonth(date);
  // };

  // const handleEndYearChange = (date) => {
  //   setEndYear(date);
  // };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
  };

  const schools = [
    "Harvard University",
    "Stanford University",
    "Massachusetts Institute of Technology (MIT)",
    "University of California, Berkeley",
    "University of Oxford",
    "California Institute of Technology (Caltech)",
    "University of Cambridge",
    "Princeton University",
    "Columbia University",
    "University of Chicago",
  ];

  const schoolOptions = schools.map((school) => ({
    value: school,
    label: school,
  }));
  const {
    saveDesignation,
    eduData,
    setEduData,
    handleCurrentlyStudyingChange,
    setShowEndDate,
    showEndDate,
    eduErrors,
  } = useProfileView({
    mode: edit,
    type: "EDUCATION",
    closeEvent,
  });
  return (
    <>
      {/* <div className=""> */}
      {/* <div className={`flex flex-col gap-4`}> */}

      <div className="h-full flex flex-col justify-between">
        <div >
        <div className=" flex justify-start items-center mb-4 border-0 border-t-[#D9D9D9] border-b-[#D9D9D9]">
          <input
            type="checkbox"
            id="linkedinUrl"
            className="mr-2 font-medium text-base custom-checkbox w-7 h-7  border border-black box-radius"
            checked={eduData.isDroppedOut}
            onChange={(e) => {
              setEduData({
                ...eduData,
                eduData: e.target.checked,
              });
            }}
          />
          <label
            htmlFor="linkedinUrl"
            className="block font-medium text-base text-gray-700 "
          >
            I am dropped out
          </label>
        </div>

        {/* {!hideHeader ? <hr /> : ""} */}
        <div className={`  mb-4`}>
          <div className={`flex flex-col gap-3 `} style={{ width: "100%" }}>
            <p>Which School or University *</p>
            <div className="w-full ">
              <input
                type="text"
                value={eduData?.school}
                onChange={(e) =>
                  setEduData({
                    ...eduData,
                    school: e.target.value,
                  })
                }
                className="w-full color-black md:p-[18px] p-[18px] font-medium text-base border border-black box-radius"
                placeholder="Type Location here"
              />
              {/* <Select
                className="color-black p-[11px] font-medium text-base  border-black box-radius"
                options={schoolOptions}
                placeholder="Click here and select"
                styles={customStyles}
                onChange={(option) => setSchool(option.value)}
              /> */}
            </div>
          </div>
          {eduErrors?.school && (
            <p className="color-red text-sm">{eduErrors?.school}</p>
          )}
        </div>
        <div className={` flex ${"flex-row gap-4 justify-start w-full"}`}>
          <div className={`flex flex-col gap-4 ${"w-[50%]"}`}>
            <div className="flex flex-col gap-3 justify-start items-start ">
              <p className=" color-black">What Degree *</p>
              <input
                type="text"
                value={eduData?.degree}
                onChange={(e) =>
                  setEduData({
                    ...eduData,
                    degree: e.target.value,
                  })
                }
                className="w-full color-black p-[18px] font-medium text-base border border-black box-radius"
                placeholder="Type Company name here"
              />
              {eduErrors?.degree && (
                <p className="color-red text-sm">{eduErrors?.degree}</p>
              )}
            </div>
            <div className="flex flex-col gap-3 justify-start items-start ">
              <p className=" color-black">Field of study *</p>
              <input
                type="text"
                value={eduData?.fieldOfStudy}
                onChange={(e) =>
                  setEduData({
                    ...eduData,
                    fieldOfStudy: e.target.value,
                  })
                }
                className="w-full color-black md:p-[18px] p-[18px] font-medium text-base border border-black box-radius"
                placeholder="Type Location here"
              />
              {eduErrors?.field && (
                <p className="color-red text-sm">{eduErrors?.field}</p>
              )}
            </div>
            <div className=" flex justify-start items-start gap-3  border-0 border-t-[#D9D9D9] border-b-[#D9D9D9]">
              <input
                type="checkbox"
                id="isCurrentlyStudying"
                checked={eduData?.isCurrentlyStudying}
                onChange={handleCurrentlyStudyingChange}
                className="mb-2 font-medium text-base  custom-checkbox br-10 border border-black "
              />
              <label
                htmlFor="isCurrentlyStudying"
                className="block font-medium text-base color-black"
              >
                I am currently studying
              </label>
            </div>
          </div>

          <div className={`${"flex flex-col gap-3"}`}>
            <div className={`flex flex-col gap-3 `}>
              <p className="color-black">Start date</p>
              <div className={`flex gap-3 }`}>
                <div className="w-full color-black p-[18px] font-medium text-base border border-black box-radius">
                  <DatePicker
                    selected={eduData?.startMonth}
                    onChange={(date) => {
                      setEduData({
                        ...eduData,
                        startMonth: date,
                      });
                    }}
                    dateFormat="MMM"
                    showMonthYearPicker
                    showFullMonthYearPicker={false}
                    showMonthDropdown
                    placeholderText="Select Month"
                    className="block w-full shadow-sm text-blue-500 focus:outline-none focus:border-indigo-500"
                  />
                </div>
                <div
                  className={`w-full color-black p-[18px] font-medium text-base border border-black box-radius `}
                >
                  <DatePicker
                    selected={eduData?.startYear}
                    onChange={(date) => {
                      setEduData({
                        ...eduData,
                        startYear: date,
                      });
                    }}
                    dateFormat="yyyy"
                    showYearPicker
                    placeholderText="Select Year"
                    className="block w-full shadow-sm focus:outline-none focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>
            {!eduData?.isCurrentlyStudying && (
              <div className={`flex flex-col gap-3`}>
                <p className="color-black">End date </p>
                <div className="flex gap-3">
                  <div className="w-full color-black p-[18px] font-medium text-base border border-black box-radius">
                    <DatePicker
                      selected={eduData?.endMonth}
                      onChange={(date) => {
                        setEduData({
                          ...eduData,
                          endMonth: date,
                        });
                      }}
                      dateFormat="MMM"
                      showMonthYearPicker
                      showFullMonthYearPicker={false}
                      showMonthDropdown
                      placeholderText="Select Month"
                      className="block w-full shadow-sm focus:outline-none focus:border-indigo-500"
                    />
                  </div>
                  <div className="w-full color-black p-[18px] font-medium text-base border border-black box-radius">
                    <DatePicker
                      selected={eduData?.endYear}
                      onChange={(date) => {
                        setEduData({
                          ...eduData,
                          endYear: date,
                        });
                      }}
                      dateFormat="yyyy"
                      showYearPicker
                      placeholderText="Select Year"
                      className="block w-full shadow-sm focus:outline-none focus:border-indigo-500"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        </div>

        {/* Save button */}
        <div className="ml-[82%] flex  gap-2 pt-3">
          <div
            onClick={() => {
              saveDesignation();
            }}
            className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
          >
            <button>Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsYourEducation;
