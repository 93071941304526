import React from "react";
import Select from "react-select";
import useProfileView from "../../hooks/useProfile";

const Designation = ({ edit, closeEvent }) => {
  const {
    designationError,
    handleDesignationSelect,
    handleRemoveSelection,
    saveDesignation,
    designations,
    designation,
  } = useProfileView({
    mode: edit,
    type: "DESIGNATION",
    closeEvent,
  });
  return (
    <div className="h-full flex flex-col justify-between">
      <div className={`flex flex-col gap-3 `}>
        <p className="font-18 color-black font-medium letterSpace">
          What's your Designation? *
        </p>
        <div className="w-full relative">
          <Select
            value={designation}
            className="color-black p-[11px] font-medium text-base border-black box-radius"
            options={
              designations.length > 0 &&
              designations.map((designation) => ({
                value: designation.name,
                label: designation.name,
              }))
            }
            placeholder="Click here and select"
            onChange={handleDesignationSelect}
          />
          {designation && (
            <button
              className="absolute top-[15px] right-[60px] ml-2 p-1 text-gray-500 rounded"
              onClick={handleRemoveSelection}
            >
              ✕
            </button>
          )}
        </div>
        {designationError && (
          <p className="color-red text-sm">{designationError}</p>
        )}
      </div>

      {/* Save button */}
      <div className=" flex ml-[82%]">
        <div
          onClick={() => {
            saveDesignation();
          }}
          className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
        >
          <button>Save</button>
        </div>
      </div>
    </div>
  );
};

export default Designation;
