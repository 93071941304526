import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    allDesignations: [],
    workExperiances: [],
    workExperianceObj: {},
    allSkills: [],
    userSkills: [],
    socialLinks: {},
    educations: [],
    educationObj: {},
  },
  reducers: {
    userInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    allDesignations: (state, action) => {
      state.allDesignations = action.payload;
    },
    workExperiances: (state, action) => {
      state.workExperiances = action.payload;
    },
    workExperianceObj: (state, action) => {
      state.workExperianceObj = action.payload;
    },
    allSkills: (state, action) => {
      state.allSkills = action.payload;
    },
    userSkills: (state, action) => {
      state.userSkills = action.payload;
    },
    socialLinks: (state, action) => {
      state.socialLinks = action.payload;
    },
    educations: (state, action) => {
      state.educations = action.payload;
    },
    educationObj: (state, action) => {
      state.educationObj = action.payload;
    },
  },
});

export const {
  userInfo,
  allDesignations,
  workExperiances,
  workExperianceObj,
  allSkills,
  userSkills,
  socialLinks,
  educations,
  educationObj,
} = userSlice.actions;

export default userSlice.reducer;
