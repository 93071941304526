import React, { useEffect, useState } from "react";
// import PopUp from './PopUp';
// import ProfilePic from "../assets/profile-pic.png";
import Star from "../assets/Vector.svg";
import TopRated from "../assets/Group 182.svg";
import EditButton from "../assets/EditButton.svg";
import deleteicon from "../assets/delete.svg";
import add from "../assets/images/add.svg";
import CountryCitySelector from "./verify/CountryCitySelector";
import Tick from "../assets/Group 748.svg";
import Five from "../assets/5.0.svg";
import FiveStar from "../assets/Star.svg";
import User from "../assets/User.svg";
import whiteEditButton from "../assets/whiteEditButton.svg";
import SocialShare from "./SocialShare";
import AddTagline from "../components/verify/AddTagline";
import AddSkills from "../components/verify/AddSkills";
import EditAddSocialLinks from "../components/verify/AddSocialLinks";
import CallButton from "../assets/CallIcon.svg";
import MessageButton from "../assets/MessageIcon.svg";
import EducationIcon from "../assets/EducationIcon.svg";
import ExperienceIcon from "../assets/ExperienceIcon.svg";
import tickIcon from "../assets/right.svg";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import grayRight from "../assets/gray-right.svg";
import greenRight from "../assets/green-right.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalHeader from "./modal/modal-header";
import Designation from "../components/verify/AddDesignation";
import Modal from "./modal";
import {
  allDesignations,
  allSkills,
  educationObj,
  educations,
  socialLinks,
  userSkills,
  workExperianceObj,
  workExperiances,
} from "../reducers/userSlice";
import {
  deleteEducation,
  deleteWorkExp,
  getAllSkills,
  getDesignations,
  getEducations,
  getSocialLinks,
  getUserSkills,
  getWorkExp,
  uploadProfilePicture,
  getProfilePicture,
} from "../actions/userDetailsService";
import { toast } from "react-toastify";
import AboutYou from "../components/verify/AboutYou";
import WhatsYourExperience from "../components/verify/WhatsYourExperience";
import AddSocialLinks from "../components/verify/AddSocialLinks";
import useProfileView from "../hooks/useProfile";
import WhatsYourEducation from "../components/verify/Education";
import { getServices } from "../actions/registrationService";

const MyProfileView = ({ profileCompletion, serviceCheck, addService }) => {
  const [profilePic, setProfilePic] = useState(User);
  // const [fileInputKey, setFileInputKey] = useState(Date.now()); // Key to reset input

  const dispatch = useDispatch();
  const {
    userDetails,
    workExperiancesArray,
    workExpObj,
    userSkillsArray,
    socialLinksObj,
    educationsArray,
    educationObjData,
  } = useSelector((state) => ({
    userDetails: state.user.userInfo,
    workExperiancesArray: state.user.workExperiances,
    workExpObj: state.user.workExperianceObj,
    userSkillsArray: state.user.userSkills,
    socialLinksObj: state.user.socialLinks,
    educationsArray: state.user.educations,
    educationObjData: state.user.educationObj,
  }));
  const { links } = useProfileView({
    mode: false,
    type: "",
  });

  const [designationModal, setDesignationModal] = useState(false);
  const [taglineModal, setTaglineModal] = useState(false);
  const [introModal, setIntroModal] = useState(false);
  const [worksModal, setWorksModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);
  const [socialLinksModal, setSocialLinksModal] = useState(false);
  const [editStatus, setEditstatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteEduStatus, setdeleteEduStatus] = useState(false);
  const [location, setLocation] = useState(false);

  // const [profilePic, setProfilePic] = useState(false);

  const [profilePicCheck, setProfilePicCheck] = useState(false);
  const [destinationCheck, setDestinationCheck] = useState(false);
  const [taglineCheck, setTaglineCheck] = useState(false);
  const [introCheck, setIntroCheck] = useState(false);
  const [workExp, setWorkExp] = useState(false);
  const [skillCheck, setSkillCheck] = useState(false);
  const [eduCheck, setEduCheck] = useState(false);
  const [socialCheck, setSocialCheck] = useState(false);
  // const [serviceCheck, setServiceCheck] = useState(false);

  const token = localStorage.getItem("authToken");

  // let profileCompletion =0



  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await uploadProfilePicture(formData, token);
        if (response.status === 200) {
          // Assuming response contains the updated userDetails with the new picture URL
          console.log("post response", response);
          const updatedUserDetails = response.data;
          console.log("updated details", updatedUserDetails);
          setProfilePic(updatedUserDetails.picture);

          const localImageUrl = URL.createObjectURL(selectedFile);
          setProfilePic(localImageUrl);

          // setFileInputKey(Date.now()); // Reset file input field
          toast.success("Profile picture uploaded successfully!", {
            toastId: "success1",
          });
        }
      } catch (error) {
        toast.error("Error uploading profile picture");
      }
    }
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        console.log("userDetails=", userDetails);
        const picturePath = userDetails.picture.split("/").pop(); // Extract the picture file name
        const response = await getProfilePicture(picturePath);
        console.log("responsee", response);
        if (response.status === 200) {
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setProfilePic(
            `data:${response.headers["content-type"]};base64,${base64}` || User
          );
        }
      } catch (error) {
        toast.error("Error fetching profile picture");
      }
    };

    if (userDetails?.picture) {
      fetchProfilePicture();
    }
  }, [userDetails?.picture]);

  // useEffect(() => {
  //   if (userDetails?.username && token) {
  //     fetchServices();
  //   }
  //   console.log("object",userDetails)

  // }, [userDetails, token]);

  // const fetchServices = async () => {
  //   try {
  //     const response = await getServices(userDetails.username, token);
  //     if(response.data.length !== 0){
  //       setServiceCheck(true)
  //     }

  //   } catch (error) {
  //     console.error("Error fetching service:", error);
  //   }
  // };

  // const test =()=>{
  //   //  profileCompletion = userDetails.profile_completion
  //   if(serviceCheck === true){
  //     profileCompletion = userDetails.profile_completion + 20
  //   }else{
  //     profileCompletion = userDetails.profile_completion
  //   }

  // }
  // test()

  useEffect(() => {
    getDesignations().then(
      (response) => {
        if (response.status === 200) {
          if(userDetails.designation !== null){
      
            setDestinationCheck(true)
          }else{
            setDestinationCheck(false)
            
          }
          
          if(userDetails.intro !== null){
            setIntroCheck(true)
          }else{
            setIntroCheck(false)
          }
      
          if(userDetails.tag_line !== null){
            setTaglineCheck(true)
          }else{
            setTaglineCheck(false)
          }
      
          if(userDetails.picture !== null){
            setProfilePicCheck(true)
          }
          toast.success("Designations fetched", {
            toastId: "success1",
          });
          dispatch(allDesignations(response.data));
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  }, [designationModal]);

  useEffect(() => {
    getWorkExp(userDetails?.username).then(
      (response) => {
        if (response.status === 200) {
          console.log("workExp fetched", response.data.length);
          if (response.data.length !== 0) {
            setWorkExp(true);
          }

          toast.success("Works fetched", {
            toastId: "success1",
          });
          dispatch(workExperiances(response.data));
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  }, [worksModal]);

  // useEffect(() => {
  //   getAllSkills().then(
  //     (response) => {
  //       if (response.status === 200) {
  //         console.log("response skil", response);
  //         if (response.data.length !== 0) {
  //           setSkillCheck(true);
  //         }
  //         toast.success("skills fetched", {
  //           toastId: "success1",
  //         });
  //         dispatch(allSkills(response.data));
  //       }
  //     },
  //     (error) => {
  //       toast.error(error.response.data.detail, {
  //         toastId: "error1",
  //       });
  //     }
  //   );
  // }, [skillsModal]);

  useEffect(() => {
    getUserSkills(userDetails?.username).then(
      (response) => {
        if (response.status === 200) {
          console.log("whats skill", response.data.length);
          if(response.data.length !== 0){
            setSkillCheck(true)
          }else{
            setSkillCheck(false)
          }
          toast.success("skills fetched", {
            toastId: "success1",
          });
          dispatch(userSkills(response.data));
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  }, [skillsModal]);

  useEffect(() => {
    getSocialLinks(userDetails?.username).then(
      (response) => {
        if (response.status === 200) {
          console.log("social links", response.data);
          if (
            Object.values(socialLinksObj).some(
              (link) => typeof link === "string" && link.trim() !== ""
            ) === true
          ) {
            setSocialCheck(true);
          }
          toast.success("links fetched", {
            toastId: "success1",
          });
          dispatch(socialLinks(response.data));
         
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  }, [socialLinksModal]);

  const deleteExperiance = async () => {
    await deleteWorkExp(workExpObj?.id).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Exp Deleted", {
            toastId: "success1",
          });
          setDeleteStatus(false);
          // navigate("/");
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  };
  const deleteEducationData = async () => {
    await deleteEducation(educationObjData?.id).then(
      (response) => {
        if (response.status === 200) {
          toast.success("edu Deleted", {
            toastId: "success1",
          });
          setdeleteEduStatus(false);
          // navigate("/");
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  };
  useEffect(() => {
    getEducations(userDetails?.username).then(
      (response) => {
        if (response.status === 200) {
          // console.log("get education", response.data.length)
          if (response.data.length !== 0) {
            setEduCheck(true);
            // console.log("get education", eduCheck)
          }
          toast.success("education fetched", {
            toastId: "success1",
          });
          dispatch(educations(response.data));
        }
      },
      (error) => {
        toast.error(error.response.data.detail, {
          toastId: "error1",
        });
      }
    );
  }, [educationModal]);

  console.log(
    "links",
    Object.values(socialLinksObj).some((x) => x !== null && x !== "")
  );
  return (
    <>
      <div className=" w-auto h-auto flex flex-col lg:flex-row  gap-5 ">
        <div className=" flex flex-col gap-5 max-w-[610px] w-full h-[129px] lg:h-[216px] overflowY md:pt-6">
          {/* user profile details */}
          <div className="w-full lg:p-[30px] lg:bg-white h-auto border-gray  br-25 ">
            <div className="w-full h-full  flex flex-col-reverse gap-8 justify-center lg:flex-row lg:gap-4 lg:justify-between lg:items-start align-middle">
              <div className="w-full px-5 lg:px-[0px] py-[10px] bg-white border lg:border-none br-30  flex flex-col gap-7">
                <div className="flex flex-col gap- ">
                  <div className="flex gap-4 letterSpace">
                    <p className="font-22 color-black ">
                      {userDetails != null
                        ? capitalize(userDetails?.given_name) +
                            " " +
                            userDetails?.family_name || "-"
                        : "-"}
                    </p>

                    <div className="rounded-[6px] px-2 text-[14px] flex items-center gap-1 bg-[#E7F6FF]">
                      <img className="" src={Tick} alt="Verified" />
                      <p className="text-[#22A0EF] pr-1">Verified</p>
                    </div>
                  </div>
                  {/* Add Designation */}
                  <div className=" w-full">
                    {userDetails != null && userDetails?.designation != null ? (
                      <div className="flex gap-4 items-center">
                        <h2 className="font-16 color-black">
                          {(userDetails?.designation != null &&
                            userDetails?.designation?.name) ||
                            "-"}
                        </h2>
                        <span
                          onClick={() => {
                            setDesignationModal(true);
                            setEditstatus(true);
                          }}
                        >
                          <img
                            src={whiteEditButton}
                            alt="image"
                            className="cursor-pointer"
                          />
                        </span>
                      </div>
                    ) : (
                      <button
                        className="color-blue font-16"
                        onClick={() => setDesignationModal(true)}
                      >
                        + Add Designation
                      </button>
                    )}
                  </div>
                  {/* Add Tagline */}
                  <div className="w-full">
                    {userDetails != null &&
                    userDetails?.tag_line != "" &&
                    userDetails?.tag_line != null ? (
                      <div className="flex gap-4 items-center">
                        <h2 className="font-16 color-gray">
                          {userDetails?.tag_line || "-"}
                        </h2>
                        <span
                          onClick={() => {
                            setTaglineModal(true);
                            setEditstatus(true);
                          }}
                        >
                          <img
                            src={whiteEditButton}
                            alt="image"
                            className="cursor-pointer"
                          />
                        </span>
                      </div>
                    ) : (
                      <button
                        className="color-blue"
                        onClick={() => {
                          setTaglineModal(true);
                        }}
                      >
                        + Add Tagline
                      </button>
                    )}
                  </div>
                  <div className="w-full">
                    <button
                      className="color-blue"
                      onClick={() => {
                        setLocation(true);
                      }}
                    >
                      + Add Location
                    </button>
                  </div>
                </div>

                <div className="flex justify-between  lg:flex-col gap-3 lg:gap-4">
                  <div className="flex gap-4 ">
                    <div className="flex px-2 py-1 items-center gap-1 primary-bg br-10 ">
                      <img className="w-[14px] h-[13px]" src={Star} alt="" />
                      <p className="text-sm color-green">5/5 (10K+ ratings)</p>
                    </div>

                    <div className="flex items-center gap-1 bg-[#E1F3FF] py-1  px-2 br-10">
                      <img
                        className="w-[14px] h-[14px]"
                        src={TopRated}
                        alt=""
                      />
                      <p className="text-blue-600">Top Rated</p>
                    </div>
                  </div>

                  <div className="flex pr-5 gap-4 lg:hidden">
                    <div className="flex flex-col justify-center gap-1 items-center">
                      <img
                        className=" w-[41px] h-[42px]"
                        src={MessageButton}
                        alt=""
                      />
                      <p className="font-12 color-black">₹5/ Minute</p>
                    </div>
                    <div className="flex flex-col justify-center gap-1 items-center">
                      <img
                        className=" w-[41px] h-[42px]"
                        src={CallButton}
                        alt=""
                      />
                      <p className="font-12 color-black">Free</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <div className="relative w-[190px]">
                  <label className="w-[157px] h-[157px] rounded-full flex justify-center overflow-hidden items-center">
                    <div className="w-[157px] h-[157px] rounded-full">
                      <img
                        className="w-full h-full"
                        // src={profilePic}
                        src={profilePic}
                        alt="Profile"
                      />
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="absolute inset-0 opacity-0 cursor-pointer"
                    />
                  </label>

                  <img
                    onClick={() =>
                      document.querySelector('input[type="file"]').click()
                    }
                    className="absolute left-[132px] top-7 bg-white rounded-full cursor-pointer"
                    src={whiteEditButton}
                    alt="Edit"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* intro */}
          {userDetails != null &&
          userDetails?.intro != null &&
          userDetails?.intro != "" ? (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center">
                  <h1 className="font-22 color-black">Intro</h1>
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      setIntroModal(true);
                      setEditstatus(true);
                    }}
                    src={EditButton}
                    alt="Edit"
                  />
                </div>
                <p className="color-black font-normal">
                  {userDetails?.intro || "-"}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center letterSpace">
                  <h1 className="font-22 color-black">Intro</h1>
                  <p className="color-black font-normal">10%</p>
                </div>
                <div className="flex flex-col gap-4 w-full p-6 br-25 h-auto border-gray-dot bg-[#F5F5F5]">
                  <p className="font-14 w-full text-center color-black font-normal">
                    Kickstart your journey with a personal introduction.
                    <br />
                    Share a bit about your passions, goals, and what you seek
                    from this platform.
                  </p>
                  <p
                    className="text-center font-16 color-blue cursor-pointer"
                    onClick={() => {
                      setIntroModal(true);
                    }}
                  >
                    + Add Intro
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* work exp */}
          {workExperiancesArray.length > 0 ? (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-2 lg:gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex justify-between items-center">
                <h1 className="font-22 color-black letterSpace">
                  Work Experience
                </h1>
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    setWorksModal(true);
                  }}
                  src={add}
                  alt="add"
                />
              </div>
              {workExperiancesArray.length > 0 &&
                workExperiancesArray.map((work, i) => {
                  return (
                    <div
                      className={`flex gap-4 items-center justify-between ${
                        i == workExperiancesArray.length - 1
                          ? ""
                          : "border-b pb-3 "
                      }`}
                    >
                      <div className="flex gap-4" style={{ width: "85%" }}>
                        <div
                          className="primary-bg flex justify-center items-center w-[78px] h-[78px] br-20"
                          style={{ width: "20%" }}
                        >
                          <img className="" src={ExperienceIcon} alt="" />
                        </div>
                        <div style={{ width: "80%" }}>
                          <p className="color-black font-semibold letterSpace">
                            {work.company_name}
                          </p>

                          <p className="color-gray">
                            {work.employment_type} · {work.start_date_year}.
                            {work.end_date_year}
                            <br />
                          </p>
                          <p className="color-gray"> {work.location}</p>
                          <p className="color-gray">{work.job_description}</p>
                        </div>
                      </div>
                      <div className="flex gap-2" style={{ width: "15%" }}>
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setDeleteStatus(true);
                            dispatch(workExperianceObj(work));
                          }}
                          src={deleteicon}
                          alt="cancel"
                        />
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setEditstatus(true);
                            setWorksModal(true);
                            dispatch(workExperianceObj(work));
                          }}
                          src={EditButton}
                          alt="Edit"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center">
                  <h1 className="font-22 color-black letterSpace">
                    Work Experience
                  </h1>
                  <p className="color-black font-normal">15%</p>
                </div>
                <div className="flex flex-col gap-4 w-full p-6 br-25 h-auto bg-[#F5F5F5] border-gray-dot">
                  <p className="font-14 w-full text-center color-black font-normal">
                    Detail your professional timeline. List past positions and
                    <br /> key accomplishments to outline your career
                    trajectory.
                  </p>
                  <p
                    className="font-16 text-center color-blue cursor-pointer"
                    onClick={() => {
                      setWorksModal(true);
                    }}
                  >
                    + Add Experience
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* skills */}
          {userDetails != null && userSkillsArray.length > 0 ? (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center">
                  <h1 className="font-22 color-black">Skills</h1>
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      setSkillsModal(true);
                      setEditstatus(true);
                    }}
                    src={EditButton}
                    alt="Edit"
                  />
                </div>
                <div className="flex flex-wrap gap-3 justify-start items-start  py-3">
                  {userSkillsArray.map((skill, index) => (
                    <div
                      key={index}
                      className="primary-bg br-15 py-2 px-4 color-black"
                    >
                      {skill.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center letterSpace">
                  <h1 className="font-22 color-black">Skills</h1>
                  <p className="color-black font-normal">10%</p>
                </div>
                <div className="flex flex-col gap-4 w-full p-6 br-25 h-auto border-gray-dot bg-[#F5F5F5]">
                  <p className="font-14 w-full text-center color-black font-normal">
                    Showcase your skills and expertise. Include anything from
                    technical skills to
                    <br />
                    soft skills that define your professional capabilities.
                  </p>
                  <p
                    className="text-center font-16 color-blue cursor-pointer"
                    onClick={() => {
                      setSkillsModal(true);
                    }}
                  >
                    + Add Skills
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* Edu exp */}
          {educationsArray.length > 0 ? (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-2 lg:gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex justify-between items-center">
                <h1 className="font-22 color-black letterSpace">Education</h1>
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    setEditstatus(false);
                    setEducationModal(true);
                  }}
                  src={add}
                  alt="add"
                />
              </div>
              {educationsArray.length > 0 &&
                educationsArray.map((work, i) => {
                  return (
                    <div
                      className={`flex gap-4 items-center justify-between ${
                        i == educationsArray.length - 1 ? "" : "border-b pb-3 "
                      }`}
                    >
                      <div className="flex gap-4" style={{ width: "85%" }}>
                        <div
                          className="primary-bg flex justify-center items-center w-[78px] h-[78px] br-20"
                          style={{ width: "20%" }}
                        >
                          <img className="" src={EducationIcon} alt="" />
                        </div>
                        <div style={{ width: "80%" }}>
                          <p className="color-black font-semibold letterSpace">
                            {work.institute_name}
                          </p>

                          <p className="color-gray">
                            {work.degree}
                            <br />
                          </p>
                          <p className="color-gray"> {work.location}</p>
                          <p className="color-gray">
                            {" "}
                            {work.start_date_year}.{work.end_date_year}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-2" style={{ width: "15%" }}>
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setdeleteEduStatus(true);
                            dispatch(educationObj(work));
                          }}
                          src={deleteicon}
                          alt="cancel"
                        />
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            setEditstatus(true);
                            setEducationModal(true);
                            dispatch(educationObj(work));
                          }}
                          src={EditButton}
                          alt="Edit"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center">
                  <h1 className="font-22 color-black letterSpace">Eduction</h1>
                  <p className="color-black font-normal">15%</p>
                </div>
                <div className="flex flex-col gap-4 w-full p-6 br-25 h-auto bg-[#F5F5F5] border-gray-dot">
                  <p className="font-14 w-full text-center color-black font-normal">
                    Document your educational qualifications. Include degrees,
                    certifications,
                    <br /> and any relevant courses that contribute to your
                    expertise.
                  </p>
                  <p
                    className="font-16 text-center color-blue cursor-pointer"
                    onClick={() => {
                      setEducationModal(true);
                    }}
                  >
                    + Add Education
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* links */}

          {userDetails != null &&
          socialLinksObj != null &&
          Object.values(socialLinksObj).some(
            (link) => typeof link === "string" && link.trim() !== ""
          ) ? (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center">
                  <h1 className="font-22 color-black">Social Links</h1>
                  <img
                    className="cursor-pointer"
                    onClick={() => {
                      setSocialLinksModal(true);
                      setEditstatus(true);
                    }}
                    src={EditButton}
                    alt="Edit"
                  />
                </div>
                <div className="flex flex-wrap gap-3 justify-start items-start  p-3">

                  {/* {console.log("social links object inside array",links)} */}
                  {links.map((link, index) =>
                    link.url != "" ? (
                      <>
                        <div className="flex justify-center items-center gap-3">
                          {link.url}
                          <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`flex items-center gap-5 px-2 py-[13px] lg:px-4 lg:py-5 w-[108px] h-[37px] lg:w-[131px] lg:h-[39px] justify-center  br-15 ${link.bgColor} ${link.textColor}`}
                          >
                            <p className="text-base">{link.name}</p>
                            <img className="" src={link.icon} alt={link.name} />
                          </a>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col px-5 py-[30px] md:p-[30px] justify-start gap-6 w-full bg-white h-auto border border-gray br-25">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div className="flex justify-between items-center letterSpace">
                  <h1 className="font-22 color-black">Social Links</h1>
                  <p className="color-black font-normal">10%</p>
                </div>
                <div className="flex flex-col gap-4 w-full p-6 br-25 h-auto border-gray-dot bg-[#F5F5F5]">
                  <p className="font-14 w-full text-center color-black font-normal">
                    Broaden your network. Link your professional and social
                    media profiles like
                    <br />
                    LinkedIn, or a personal blog.
                  </p>
                  <p
                    className="text-center font-16 color-blue cursor-pointer"
                    onClick={() => {
                      setSocialLinksModal(true);
                    }}
                  >
                    + Add Links
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5 max-w-[400px] w-full h-[129px] lg:h-[216px]  md:pt-6">
          <div className="flex  justify-start  w-full bg-white h-[250px] border border-gray br-25">
            <div className="w-[40%] h-full p-6 flex flex-col justify-between">
              <div className="w-20 h-20 ">
                <div className="w-full h-full rounded-full bg-[#69BC56] p-2 ">
                  <div className=" relative w-full h-full rounded-full ">
                    {/* <CircularProgressbar value={66} /> */}
                    <CircularProgressbar
                      value={profileCompletion}
                      strokeWidth={50}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                      })}
                    />
                  </div>
                </div>
              </div>
              <p className="text-[#404040] text-[14px]">
                Profile Setup Completed{" "}
              </p>
              <p className="text-[#404040] text-[22px]">
                {profileCompletion}%{" "}
              </p>
            </div>

            <div className="border border-dotted bor"> </div>

            <div className="w-[60%] h-full p-6">
              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!profilePicCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !profilePicCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Profile Picture
                  </p>
                </div>
                {!profilePicCheck ? (
                  <p className="text-[#404040] text-[14px]">5% </p>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!destinationCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !destinationCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Designation
                  </p>
                </div>
                {!destinationCheck ? (
                  <p className="text-[#404040] text-[14px]">10% </p>
                ) : (
                  ""
                )}
              </div>
              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!taglineCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !taglineCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Tagline
                  </p>
                </div>
                {!taglineCheck ? (
                  <p className="text-[#404040] text-[14px]">5% </p>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!introCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !introCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Intro
                  </p>
                </div>
                {!introCheck ? (
                  <p className="text-[#404040] text-[14px]">10% </p>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!workExp ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !workExp ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Work Experience
                  </p>
                </div>
                {!workExp ? (
                  <p className="text-[#404040] text-[14px]">15% </p>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!skillCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !skillCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Skills
                  </p>
                </div>
                {!skillCheck && (
                  <p className="text-[#404040] text-[14px]">15% </p>
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!eduCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !eduCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Education
                  </p>
                </div>
                {!eduCheck ? (
                  <p className="text-[#404040] text-[14px]">15% </p>
                ) : (
                  ""
                )}
              </div>

              <div className="w-full flex justify-between">
                <div className="flex justify-center items-center gap-1">
                  <div>
                    {!socialCheck ? (
                      <img src={grayRight} alt="" />
                    ) : (
                      <img src={greenRight} alt="" />
                    )}
                  </div>
                  <p
                    className={` text-[14px] ${
                      !socialCheck ? "text-[#404040]" : "text-[#69BC56]"
                    }`}
                  >
                    Social Links
                  </p>
                </div>
                {!socialCheck && (
                  <p className="text-[#404040] text-[14px]">5%</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col p-[30px] justify-start gap-3 w-full bg-white h-auto border border-gray br-25">
            <div className="flex  items-center justify-between">
              <h1 className="font-22 color-black">Add Services</h1>
              {serviceCheck ? "" : <p>20%</p>}
            </div>

            <p className="">
              Define and customize your offerings. Create and list various
              mentorship packages, specifying their scope, duration, and costs.
            </p>
            {/* <Link> */}
            <p
              className="text-base mt-16 text-[#22A0EF] cursor-pointer"
              onClick={addService}
            >
              + Add Services
            </p>
            {/* </Link> */}
          </div>
          <SocialShare />
        </div>

       
      </div>
      {designationModal && (
        <>
          <Modal
            open={designationModal}
            height="704px"
            width="958px"
            onClose={() => {
              setDesignationModal(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Designation" : "Add Designation"}
                content={<></>}
              />

              <div className="h-full pt-6 pb-10">
                <Designation
                  edit={editStatus}
                  closeEvent={setDesignationModal}
                />
              </div>
            </div>
          </Modal>
        </>
      )}

      {location && (
        <>
          <Modal
            open={location}
            height="704px"
            width="958px"
            onClose={() => {
              setLocation(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Location" : "Add Location"}
                content={<></>}
              />

              <div className="pt-6 h-full pb-10">
                <CountryCitySelector
                  edit={editStatus}
                  closeEvent={setLocation}
                />
              </div>
            </div>
          </Modal>
        </>
      )}

      {taglineModal && (
        <>
          <Modal
            open={taglineModal}
            height="704px"
            width="958px"
            onClose={() => {
              setTaglineModal(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Tagline" : "Add Tagline"}
                content={<></>}
              />

              <div className="h-full pt-6 pb-10">
                <AddTagline edit={editStatus} closeEvent={setTaglineModal} />
              </div>
            </div>
          </Modal>
        </>
      )}
      {introModal && (
        <>
          <Modal
            open={introModal}
            height="704px"
            width="958px"
            onClose={() => {
              setIntroModal(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Intro" : "Add Intro"}
                content={<></>}
              />

              <div className="h-full pb-10 pt-6">
                <AboutYou edit={editStatus} closeEvent={setIntroModal} />
              </div>
            </div>
          </Modal>
        </>
      )}
      {worksModal && (
        <>
          <Modal
            open={worksModal}
            height="704px"
            width="958px"
            onClose={() => {
              setWorksModal(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Work Experience" : "Add Experience"}
                content={<></>}
              />

              <div className="h-full pb-10 pt-6">
                <WhatsYourExperience
                  edit={editStatus}
                  closeEvent={setWorksModal}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
      {deleteStatus && (
        <>
          <Modal
            open={deleteStatus}
            height="auto"
            width="35%"
            onClose={() => {
              setDeleteStatus(false);
            }}
          >
            <div>
              <ModalHeader title={"Delete Experience"} content={<></>} />

              <div className="pt-6">
                <p className="color-black">
                  Are you sure you want to delete Experience
                </p>
                <div className="flex">
                  <button
                    className="mt-3 color-black bg-gray py-4 px-14 flex justify-center items-center text-lg font-semibold br-20 mr-4"
                    onClick={() => setDeleteStatus(true)}
                  >
                    NO
                  </button>
                  <button
                    className="mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
                    onClick={() => deleteExperiance()}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {skillsModal && (
        <>
          <Modal
            open={skillsModal}
            height="704px"
            width="958px"
            onClose={() => {
              setSkillsModal(false);
            }}
          >
            <div className="h-full">
              <ModalHeader
                title={editStatus ? "Edit Skills" : "Add Skills"}
                content={<></>}
              />

              <div className="pt-6 h-full pb-10">
                <AddSkills edit={editStatus} closeEvent={setSkillsModal} />
              </div>
            </div>
          </Modal>
        </>
      )}
      {socialLinksModal && (
        <>
          <Modal
            open={socialLinksModal}
            height="704px"
            width="958px"
            onClose={() => {
              setSocialLinksModal(false);
            }}
          >
            <div>
              <ModalHeader
                title={editStatus ? "Edit Social Links" : "Add Social Links"}
                content={<></>}
              />

              <div className="pt-6">
                <AddSocialLinks
                  edit={editStatus}
                  closeEvent={setSocialLinksModal}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
      {educationModal && (
        <>
          <Modal
            open={educationModal}
            height="704px"
            width="958px"
            onClose={() => {
              setEducationModal(false);
            }}
          >
            <div className="h-full ">
              <ModalHeader
                title={editStatus ? "Edit Education" : "Add Education"}
                content={<></>}
              />

              <div className="pt-6 h-full pb-10">
                <WhatsYourEducation
                  edit={editStatus}
                  closeEvent={setEducationModal}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
      {deleteEduStatus && (
        <>
          <Modal
            open={deleteEduStatus}
            height="auto"
            width="35%"
            onClose={() => {
              setdeleteEduStatus(false);
            }}
          >
            <div>
              <ModalHeader title={"Delete Education"} content={<></>} />

              <div className="pt-6">
                <p className="color-black">
                  Are you sure you want to delete Education
                </p>
                <div className="flex">
                  <button
                    className="mt-3 color-black bg-gray py-4 px-14 flex justify-center items-center text-lg font-semibold br-20 mr-4"
                    onClick={() => setdeleteEduStatus(true)}
                  >
                    NO
                  </button>
                  <button
                    className="mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
                    onClick={() => deleteEducationData()}
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default MyProfileView;
