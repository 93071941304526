import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import User from "../assets/User.svg";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import active from "../assets/active.svg";

const SocialShare = () => {
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userInfo,
  }));

  const [copySuccess, setCopySuccess] = useState(false);

  // Ensure profile details are available
  if (!userDetails) return null;

  const { picture, given_name, designation, tag_line } = userDetails;
  const profileUrl = `${window.location.origin}/profile/${userDetails.username}`; // Replace with your profile URL

  const copyToClipboard = () => {
    navigator.clipboard.writeText(profileUrl).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="social-share-container bg-white py-6 border br-25 border-gray rounded-lg">
      <h1 className='px-[30px] text-[18px]'>Publish your profile</h1>
      <div className=' mt-2 border-b-[1px] border-dashed border-gray w-full'></div>
      
      <div className="px-[30px] mt-2 flex items-center justify-between mb-4">
        <div>
          <h3 className="text-[22px] color-black font-semibold">{given_name}</h3>
          <p className="color-black">Experienced {designation?.name}</p>
          <p className='color-black'>Ready to Guide You</p>
        </div>
        <div className='w-[90px] h-[90px] rounded-full border-4 border-orange-600'>
          <img src={picture || User} alt={given_name} className="w-full h-full rounded-full" />
        </div>
      </div>

      <div className=' mt-2 border-b-[1px] border-dashed  w-full'></div>
      
      <div className='mt-4 px-8'>
        <p className='mt-2 color-black text-[12px] font-bold'>KEY SERVICES OFFERED:</p>
        <ul>
          <li className='mt-2 color-black'>UI/UX Design Consulting</li>
        </ul>
      </div>

      <div className='mt-28 border-b-[1px] border-dashed  w-full'></div>
      <div className='flex mt-3 gap-2 px-8'>
        <img src={active} alt="active" />
        <p>Available Now for Calls</p>
      </div>
      <div className='mt-3 border-b-[1px] border-dashed  w-full'></div>
<div className='flex items-center px-8 gap-4'>
<div className="flex items-center gap-2 mt-3 ">
        <p>Share : </p>
        <button 
          onClick={copyToClipboard} 
          className="bg-white border border-gray rounded-[14px] hover:bg-blue-700 text-base text-blue-500 hover:text-white py-1 px-4 "
        >
          {copySuccess ? "Copied!" : "Copy URL"}
        </button>
      </div>
      <div className="flex gap-1  mt-3 ">
        <FacebookShareButton url={picture} quote={`Check out ${given_name}'s profile on our platform!`}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={picture} title={`Check out ${given_name}'s profile!`}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={picture} title={`Check out ${given_name}'s profile`}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

     
      </div>
    </div>
  );
};

export default SocialShare;





















// import React from 'react';
// import { useSelector } from 'react-redux';
// import User from "../assets/User.svg"
// import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
// // import active from "../assets/ActiveButton.png"
// import active from "../assets/active.svg";
// const SocialShare = () => {
//   const { userDetails } = useSelector((state) => ({
//     userDetails: state.user.userInfo,
//   }));

//   // Ensure profile details are available
//   if (!userDetails) return null;
// console.log("social share", userDetails)
//   const { picture, given_name, tag_line, } = userDetails;

//   const profileUrl = `${window.location.origin}/profile/${userDetails.username}`; // Replace with your profile URL

//   return (
//     <div className="social-share-container bg-white py-6 border br-25 border-gray rounded-lg">
//         <h1 className='px-[30px] text-[18px]'>Publish your profile</h1>
//         <div className=' mt-2 border-b-[1px] border-dashed border-gray w-full'></div>
//       <div className="px-[30px] mt-2 flex items-center justify-between mb-4">
//       <div>
//           <h3 className=" text-[22px] color-black font-semibold">{given_name}</h3>
//           {/* <p className="text-gray-600">{designation}</p> */}
//           <p className="color-black">{tag_line}</p>
//           <p className='color-black'>Ready to Guide You</p>
//         </div>
//         <div className='w-[90px] h-[90px] rounded-full  border-4 border-orange-600'>
//         <img src={picture || User} alt={given_name} className="w-full h-full rounded-full" />
        
//         </div>
       
//       </div>
//       <div className=' mt-2 border-b-[1px] border-dashed border-gray w-full'></div>
    
//     <div className='mt-4 px-8'>
//         <p className='mt-2 color-black text-[12px] font-bold'>KEY SERVICES OFFERED:</p>
//         <ul>
//             <li className=' mt-2 color-black'>UI/UX Design Consulting</li>
//         </ul>
//     </div>

//     <div className=' mt-28 border-b-[1px] border-dashed border-gray w-full'></div>
//     <div className='flex mt-3 gap-2 px-8'>
//         <img  src={active} alt="active" />
//         <p>Available Now for Calls</p>
//     </div>
//     <div className=' mt-3 border-b-[1px] border-dashed border-gray w-full'></div>
    
//       <div className="flex gap-2 mt-3 px-8">
//         <FacebookShareButton url={picture} quote={`Check out ${given_name}'s profile on our platform!`}>
//           <FacebookIcon size={32} round />
//         </FacebookShareButton>
//         <TwitterShareButton url={picture} title={`Check out ${given_name}'s profile!`}>
//           <TwitterIcon size={32} round />
//         </TwitterShareButton>
//         <LinkedinShareButton url={picture} title={`Check out ${given_name}'s profile`} summary={tag_line}>
//           <LinkedinIcon size={32} round />
//         </LinkedinShareButton>
//       </div>
//     </div>
//   );
// };

// export default SocialShare;
