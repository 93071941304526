import React, { useState } from "react";
import useProfileView from "../../hooks/useProfile";

const AddTagline = ({ edit, closeEvent }) => {
  const { saveDesignation, setTagline, tagline, taglineError } = useProfileView(
    {
      mode: edit,
      type: "TAGLINE",
      closeEvent,
    }
  );


  const [text, setText] = useState('');
  const maxCharacters = 85;

  const handleChange = (e) => {
    setTagline(e.target.value)
    setText(e.target.value);
  };

  return (
    <div className="h-full flex flex-col justify-between ">
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2 `}>
          <label
            htmlFor="description"
            className="block font-medium text-base color-gray mt-2"
          >
            Add Tagline
          </label>
          <textarea
            id="description"
            className={`mb-2 font-medium text-base w-full h-[224px] br-20 border border-black p-2`}
            value={tagline}
            maxLength={maxCharacters}
            onChange={handleChange }
            placeholder=""
          ></textarea>
        <p className="flex justify-end">{text.length}/{maxCharacters}</p>
        </div>
        {taglineError && <p className="text-sm color-red">{taglineError}</p>}
      </div>

      <div className=" flex gap-2 ml-[82%]">
        <div
          onClick={() => {
            saveDesignation();
          }}
          className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
        >
          <button>Save</button>
        </div>
      </div>
    </div>
  );
};

export default AddTagline;
