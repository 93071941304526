import React, { useState } from "react";
import useProfileView from "../../hooks/useProfile";

function AboutYou({ edit, closeEvent }) {
  const { saveDesignation, intro, setIntro, taglineError } = useProfileView({
    mode: edit,
    type: "INTRO",
    closeEvent,
  });

  const [text, setText] = useState('');
  const maxCharacters = 900;

  const handleChange = (e) => {
    setIntro(e.target.value)
    setText(e.target.value);
  };

  return (
    <div className="h-full flex flex-col justify-between">
      <div className={`flex flex-col gap-4`}>
        <textarea
          value={intro}
           onChange={handleChange}
          maxLength={maxCharacters}
          id="description"
          className={` mb-2 font-medium text-base w-full h-80 br-20 border border-black p-2`}
          placeholder="Type here..."
        ></textarea>
           <p className="flex justify-end">{text.length}/{maxCharacters}</p>
      </div>

      <div className=" flex ml-[82%] gap-2">
        <div
          onClick={() => {
            saveDesignation();
          }}
          className=" mt-3 text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
        >
          <button>Save</button>
        </div>
      </div>

      {/* <div className="hidden md:block z-[5] absolute top-6 left-[-15%]  border-l-2 border-dotted border-l-[#9C9C9C] h-[120%] "></div> */}
    </div>
  );
}

export default AboutYou;
