import React, { useState } from "react";
import tick from "../../assets/Gray tick.svg";
import whitetick from "../../assets/white-tick.svg";
import close from "../../assets/close.png";
import useProfileView from "../../hooks/useProfile";
import { allSkills } from "../../reducers/userSlice";
import { useDispatch } from "react-redux";

function AddSkills({ edit, closeEvent }) {
  const dispatch = useDispatch();
  const {
    saveDesignation,
    taglineError,
    allSkillsArray,
    handleSkillClick,
    handleSkillMouseEnter,
    handleSkillMouseLeave,
    hoveredSkill,
    selectedSkills,
  } = useProfileView({
    mode: edit,
    type: "SKILLS",
    closeEvent,
  });
  const [skills, setSkills] = useState(allSkillsArray);
  const [searchInput, setSearchInput] = useState("");
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    setSearchInput(event.target.value);
    console.log(allSkillsArray, value, "allSkillsArray");
    if (value != "") {
      result = allSkillsArray.filter((data) => {
        if (data.name.toLowerCase().includes(value)) {
          return data.name.toLowerCase().search(value) != -1;
        }
      });
      setSkills(result);
    } else {
      setSkills(allSkillsArray);
    }
  };
  return (
    <div className="h-full flex flex-col">
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-3 `}>
          <input
            id="search"
            className={` mb-2 font-medium text-base w-full br-20 border border-black p-2`}
            placeholder="Search skills..."
            value={searchInput}
            onChange={handleSearch}
          ></input>
        </div>

        <div
          className="overflow-y-auto"
          style={{ height: "calc(100vh - 500px)" }}
        >
          {skills.length > 0 ? (
            <div className="grid grid-cols-4 gap-2  overflow-x-hidden">
              {skills.map((skill, index) => (
                <div
                  key={index}
                  className={`flex p-2 gap-2 hover:text-white ${
                    selectedSkills.some((person) => person.id === skill.id)
                      ? "bg-custom-gradient text-white"
                      : "bg-[#F5F5F5] text-black"
                  } hover:bg-custom-gradient cursor-pointer rounded-xl items-center`}
                  onClick={() => handleSkillClick(skill)}
                  onMouseEnter={() => handleSkillMouseEnter(skill.id)}
                  onMouseLeave={handleSkillMouseLeave}
                >
                  <img
                    src={
                      selectedSkills.some((person) => person.id === skill.id) ||
                      hoveredSkill === skill.id
                        ? whitetick
                        : tick
                    }
                    alt="tick icon"
                    className="w-5 h-5"
                  />
                  <p>{skill.name}</p>
                </div>
              ))}
            </div>
          ) : (
            "No Skills Added"
          )}
        </div>
      </div>

      <div className=" flex">
        <div
          onClick={() => {
            saveDesignation();
          }}
          className=" text-white bg-custom-gradient py-4 px-14 flex justify-center items-center text-lg font-semibold br-20"
        >
          <button>Save</button>
        </div>
      </div>
    </div>
  );
}

export default AddSkills;
